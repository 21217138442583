import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ControlServiceComponentActions,
  ControlServiceDefaultActions,
  ControlServiceReceiversActions,
  ControlServiceSendersActions,
} from './control-service.actions';
import { catchError, map, mergeMap, of, skip, switchMap } from 'rxjs';
import {
  AppSupporterConfiguration,
  ComponentsService,
  DefaultService,
  ReceiversService,
  SendersService,
} from '@shared/data-provider/app-supporter';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable()
export class ControlServiceEffects {
  constructor(
    private actions$: Actions,
    private componentsService: ComponentsService,
    private defaultService: DefaultService,
    private receiversService: ReceiversService,
    private sendersService: SendersService,
    private settingsService: SettingsService
  ) {
    settingsService.general.applicationConfig$
      .pipe(
        skip(1),
        map(config => config.apiControlServiceURL)
      )
      .subscribe(baseUrl => {
        const appSupporterConfiguration = new AppSupporterConfiguration({
          basePath: baseUrl,
        });

        componentsService.configuration = appSupporterConfiguration;
        defaultService.configuration = appSupporterConfiguration;
        receiversService.configuration = appSupporterConfiguration;
        sendersService.configuration = appSupporterConfiguration;
      });
  }

  readAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceComponentActions.readAll),
      switchMap(() =>
        this.componentsService
          .getComponents()
          .pipe(map(components => ControlServiceComponentActions.readAllSuccess({ components })))
      )
    );
  });

  createComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceComponentActions.create),
      mergeMap(arg =>
        this.componentsService
          .createComponent(arg.component)
          .pipe(map(apiResponse => ControlServiceComponentActions.createSuccess({ apiResponse })))
      ),
      switchMap(() => of(ControlServiceComponentActions.readAll()))
    );
  });

  updateComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceComponentActions.update),
      switchMap(arg =>
        this.componentsService
          .updateComponent(arg.id, arg.component)
          .pipe(
            switchMap(apiResponse => [
              ControlServiceComponentActions.updateSuccess({ apiResponse }),
              ControlServiceComponentActions.read({ id: arg.id }),
            ])
          )
      )
    );
  });

  readComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceComponentActions.read),
      switchMap(arg =>
        this.componentsService
          .getComponent(arg.id)
          .pipe(
            switchMap(component => [
              ControlServiceComponentActions.clearCurrentItemAndStatus(),
              ControlServiceComponentActions.readSuccess({ component }),
              ControlServiceDefaultActions.status({ id: arg.id }),
            ])
          )
      )
    );
  });

  componentRestart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceDefaultActions.restart),
      switchMap(arg =>
        this.defaultService
          .componentsIdRestartPost(arg.id)
          .pipe(map(apiResponse => ControlServiceDefaultActions.restartSuccess({ apiResponse })))
      )
    );
  });

  componentStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceDefaultActions.status),
      switchMap(arg =>
        this.defaultService
          .componentsIdStatusPost(arg.id)
          .pipe(map(status => ControlServiceDefaultActions.statusSuccess({ status })))
      )
    );
  });

  componentNewItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceComponentActions.newItem),
      switchMap(() => of(ControlServiceComponentActions.newItemSuccess()))
    );
  });

  receiverReadChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceReceiversActions.readChannels),
      switchMap(arg =>
        this.receiversService.getChannels(arg.id).pipe(
          map(channels => ControlServiceReceiversActions.readChannelsSuccess({ channels })),
          catchError(error =>
            of(
              ControlServiceReceiversActions.readChannelsFailure({
                apiResponse: {
                  type: 'error',
                  message: error.message,
                  detail: '',
                },
              })
            )
          )
        )
      )
    );
  });

  receiverChannelCommand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceReceiversActions.sendCommand),
      switchMap(arg =>
        this.receiversService.executeOperation(arg.id, arg.channel, arg.operation).pipe(
          map(apiResponse => {
            // FIXME в ответе приходит Blob, нужен ApiResponse
            // TODO временно передается заглушка пока не починен тип ответа
            // return ControlServiceReceiversActions.sendCommandSuccess({ apiResponse });
            return ControlServiceReceiversActions.sendCommandSuccess({
              apiResponse: {
                type: 'ok',
                message: '',
                detail: '',
              },
            });
          }),
          catchError(error =>
            of(
              ControlServiceReceiversActions.sendCommandFailure({
                apiResponse: {
                  type: 'error',
                  message: error.message,
                  detail: '',
                },
              })
            )
          )
        )
      )
    );
  });

  senderReadChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceSendersActions.readChannels),
      switchMap(arg =>
        this.sendersService.getChannels(arg.id).pipe(
          map(channels => ControlServiceSendersActions.readChannelsSuccess({ channels })),
          catchError(error =>
            of(
              ControlServiceSendersActions.readChannelsFailure({
                apiResponse: {
                  type: 'error',
                  message: error.message,
                  detail: '',
                },
              })
            )
          )
        )
      )
    );
  });

  senderChannelCommand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceSendersActions.sendCommand),
      switchMap(arg =>
        this.sendersService.executeOperation(arg.id, arg.channel, arg.operation).pipe(
          map(apiResponse => {
            // FIXME в ответе приходит Blob, нужен ApiResponse
            // TODO временно передается заглушка пока не починен тип ответа
            // return ControlServiceSendersActions.sendCommandSuccess({ apiResponse });
            return ControlServiceSendersActions.sendCommandSuccess({
              apiResponse: {
                type: 'ok',
                message: '',
                detail: '',
              },
            });
          }),
          catchError(error =>
            of(
              ControlServiceReceiversActions.sendCommandFailure({
                apiResponse: {
                  type: 'error',
                  message: error.message,
                  detail: '',
                },
              })
            )
          )
        )
      )
    );
  });

  editAddressesForChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ControlServiceSendersActions.editAddresses),
      switchMap(arg =>
        this.sendersService.setAddresses(arg.id, arg.channel, arg.addresses).pipe(
          map(apiResponse => {
            // FIXME в ответе приходит Blob, нужен ApiResponse
            // TODO временно передается заглушка пока не починен тип ответа
            // return ControlServiceSendersActions.sendCommandSuccess({ apiResponse });
            return ControlServiceSendersActions.editAddressesSuccess({
              apiResponse: {
                type: 'ok',
                message: '',
                detail: '',
              },
            });
          }),
          catchError(error =>
            of(
              ControlServiceSendersActions.editAddressesFailure({
                apiResponse: {
                  type: 'error',
                  message: error.message,
                  detail: '',
                },
              })
            )
          )
        )
      )
    );
  });
}
