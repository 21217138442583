/**
 * App supporter
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SenderChannel { 
    name?: string;
    enabled?: boolean;
    altName?: string;
    info?: string;
    type?: SenderChannel.TypeEnum;
    addresses?: Array<string>;
}
export namespace SenderChannel {
    export type TypeEnum = 'active' | 'nonActive' | 'unknown';
    export const TypeEnum = {
        Active: 'active' as TypeEnum,
        NonActive: 'nonActive' as TypeEnum,
        Unknown: 'unknown' as TypeEnum
    };
}


