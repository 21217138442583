import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ApiResponse, Component, ReceiverChannel, SenderChannel, Status } from '@shared/data-provider/app-supporter';

export const ControlServiceComponentActions = createActionGroup({
  source: 'Components',
  events: {
    'Read All': emptyProps(),
    'Read All Success': props<{ components: Component[] }>(),
    Create: props<{ component: Component }>(),
    'Create Success': props<{ apiResponse: ApiResponse }>(),
    Update: props<{ id: number; component: Component }>(),
    'Update Success': props<{ apiResponse: ApiResponse }>(),
    Read: props<{ id: number }>(),
    'Read Success': props<{ component: Component }>(),
    'New Item': emptyProps(),
    'New Item Success': emptyProps(),
    'Clear Current Item and Status': emptyProps(),
  },
});

export const ControlServiceDefaultActions = createActionGroup({
  source: 'Default',
  events: {
    Restart: props<{ id: number }>(),
    'Restart Success': props<{ apiResponse: ApiResponse }>(),
    Status: props<{ id: number }>(),
    'Status Success': props<{ status: Status }>(),
  },
});

export const ControlServiceReceiversActions = createActionGroup({
  source: 'Receivers',
  events: {
    'Read Channels': props<{ id: number }>(),
    'Read Channels Success': props<{ channels: ReceiverChannel[] }>(),
    'Read Channels Failure': props<{ apiResponse: ApiResponse }>(),
    'Send Command': props<{ id: number; channel: string; operation: 'start' | 'stop' }>(),
    'Send Command Success': props<{ apiResponse: ApiResponse }>(),
    'Send Command Failure': props<{ apiResponse: ApiResponse }>(),
  },
});

export const ControlServiceSendersActions = createActionGroup({
  source: 'Senders',
  events: {
    'Read Channels': props<{ id: number }>(),
    'Read Channels Success': props<{ channels: SenderChannel[] }>(),
    'Read Channels Failure': props<{ apiResponse: ApiResponse }>(),
    'Send Command': props<{ id: number; channel: string; operation: 'start' | 'stop' }>(),
    'Send Command Success': props<{ apiResponse: ApiResponse }>(),
    'Send Command Failure': props<{ apiResponse: ApiResponse }>(),
    'Edit Addresses': props<{ id: number; channel: string; addresses: string[] }>(),
    'Edit Addresses Success': props<{ apiResponse: ApiResponse }>(),
    'Edit Addresses Failure': props<{ apiResponse: ApiResponse }>(),
  },
});
