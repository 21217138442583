import { KeycloakProfile } from 'keycloak-js';
import { KeycloakTokenParsed } from 'keycloak-js';
import { TimeFormat } from '@shared/models/time-format';
import { Module } from '@shared/models/module-name';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicationConfig } from './settings';

interface UserFilter {
	id: number;
	name: string;
	folderId: number;
	folderName: string;
	data: string;
	count?: number;
}

export interface NotificationSubscribe {
	name: string;
	subscribe: string;
}

export interface UserSettings {
	// Логин пользователя из keycloak
	id?: string;
	// Язык системы
	lang: string;
	// Часовой пояс
	timezone: string;
	// Формат отображения времени в системе UTC или LOC для пользователя
	time: TimeFormat | string;
	// Количество сообщений на странице в модуле обработки сообщений
	perPage: number;
	// Период в часах для отображения рейсов в центровке
	displayedPeriod: number;
	// ! Список уведомлений - устаревшее, модуль на пересмотр
	// notifications: Array<UserNotification>;
	// Пользовательские фильтры
	comManUserFilters: Array<UserFilter>;
	// Флаг отображения списка папок в модуле обработки сообщений
	showFolderThree: boolean;
	// Идентификатор загружаемого модуля по умолчанию из enum Module
	loadableModule: number;
	// Копировали ли текст сообщения при ответе
	copyTextForReply: boolean;
	// Избранная папка пользователя
	favoriteFolderId: number;
	// Тип отображаемого графика линейной центровки
	linearCenteringGraphicsType: string;
	// Подписки на уведомления
	notificationsSubscribe: Array<NotificationSubscribe>;
	// Включение опции уведомлений
	notificationsIsEnabled: boolean;
}

// ! Перенести в настройки комана
const CM_PER_PAGE_MESSAGES = 20;
const WB_FLIGHTS_DISPLAY_PERIOD = 24;

const EMPTY_SETTINGS: UserSettings = {
	lang: '',
	timezone: '',
	time: '',
	perPage: CM_PER_PAGE_MESSAGES,
	displayedPeriod: WB_FLIGHTS_DISPLAY_PERIOD,
	comManUserFilters: [],
	showFolderThree: true,
	loadableModule: 0,
	copyTextForReply: true,
	favoriteFolderId: null,
	linearCenteringGraphicsType: '',
	notificationsSubscribe: [],
	notificationsIsEnabled: false,
};

/**
 * Класс для хранения данных о текущем пользователи
 */
@Injectable({
	providedIn: 'root',
})
export class User {
	// Профиль из Keycloak
	private profile: KeycloakProfile;
	private settings: UserSettings = EMPTY_SETTINGS;
	private defaultConfig: ApplicationConfig;

	// поток об изменении состояния токена
	token$ = new BehaviorSubject<KeycloakTokenParsed>(null);
	// поток об изменении логина пользователя
	login$ = new BehaviorSubject<string>(null);
	// поток языка системы
	language$ = new BehaviorSubject<string>(null);
	// поток с ролями пользователя
	roles$ = new BehaviorSubject<string[]>(null);
	// Поток с настройками пользователя
	settings$ = new BehaviorSubject<UserSettings>(null);
	time$ = new BehaviorSubject<TimeFormat>(null);
	timeZone$ = new BehaviorSubject<string>(null);
	displayedPeriod$ = new BehaviorSubject<number>(24);
	// поток подписок на уведомления
	notificationsSubscribe$ = new BehaviorSubject<NotificationSubscribe[]>(null);
	notificationsIsEnabled$ = new BehaviorSubject<boolean>(null);

	/**
	 * Функция устанавливает значение токена пользователя.
	 * @param {KeycloakTokenParsed} value - токен пользователя.
	 */
	setToken(value: KeycloakTokenParsed) {
		this.token$.next(value);
	}

	/**
	 * Функция возвращает токен пользователя.
	 */
	getToken(): KeycloakTokenParsed {
		return this.token$.getValue();
	}

	/**
	 * Функция устанавливает данные профиля пользователя из Keycloak.
	 * @param {KeycloakProfile} value - Параметр value имеет тип KeycloakProfile. Он
	 * представляет объект профиля пользователя.
	 */
	setProfile(value: KeycloakProfile) {
		this.profile = value;
		this.login$.next(this.profile.username);
	}

	/**
	 * Функция устанавливает роли пользователя.
	 * @param {string[]} value - Массив строк с ролями пользователя
	 */
	setRoles(value: string[]) {
		this.roles$.next(value);
	}

	/**
	 * Функция возвращает массив строк ролей пользователя.
	 */
	getRoles(): string[] {
		return this.roles$.getValue();
	}

	/**
	 * Функция возвращает логин пользователя.
	 */
	getLogin(): string {
		return this.profile?.username;
	}

	/**
	 * Функция возвращает имя пользователя.
	 */
	getFirstName(): string {
		return this.profile?.firstName;
	}

	/**
	 * Функция возвращает фамилию пользователя.
	 */
	getLastName(): string {
		return this.profile?.lastName;
	}

	setUserSettings(value: UserSettings) {
		Object.assign(this.settings, value);
		this.checkUserConfigForBadValues();
		this.settings$.next(this.settings);
		this.language$.next(this.settings.lang);
		this.time$.next(this.settings.time as TimeFormat);
		this.timeZone$.next(this.settings.timezone);
		this.notificationsSubscribe$.next(this.settings.notificationsSubscribe);
		this.notificationsIsEnabled$.next(this.settings.notificationsIsEnabled);
		this.displayedPeriod$.next(this.settings.displayedPeriod);
	}

	private checkUserConfigForBadValues() {
		if (this.settings.perPage === null || this.settings.perPage === undefined) {
			this.settings.perPage = CM_PER_PAGE_MESSAGES;
		}

		if (this.settings.displayedPeriod === null || this.settings.displayedPeriod === undefined) {
			this.settings.displayedPeriod = WB_FLIGHTS_DISPLAY_PERIOD;
		}

		if (this.settings.loadableModule === null) {
			this.settings.loadableModule = Module.Unknown;
		}

		if (this.settings.lang == 'en') {
			this.settings.lang = 'EN';
		} else if (this.settings.lang == 'ru') {
			this.settings.lang = 'RU';
		}
	}

	defaultSettings() {
		return {
			id: this.profile.username,
			lang: 'EN',
			time: 'Z',
			loadableModule: 0,
		};
	}

	getSettingsSection(id: Module) {
		switch (id) {
			case Module.Unknown:
				return {
					lang: this.settings.lang,
					time: this.settings.time,
					timezone: this.settings.timezone,
					loadableModule: this.settings.loadableModule,
				};
			case Module.ComMan:
				return {
					perPage: this.settings.perPage,
					comManUserFilters: this.settings.comManUserFilters,
					showFolderThree: this.settings.showFolderThree,
					copyTextForReply: this.settings.copyTextForReply,
				};
			case Module.WeightBalance:
				return {
					displayedPeriod: this.settings.displayedPeriod,
				};
			default:
				return {};
		}
	}

	getLanguage() {
		// TODO кривое использование и мутирование данных
		return this.language$.getValue() ? this.language$.getValue() : 'EN';
	}

	setLanguage(value) {
		this.settings.lang = value;
		this.updateSettingsStream();
		this.language$.next(value);
	}

	setDefaultConfig(config: ApplicationConfig) {
		this.defaultConfig = config;
	}

	getTime(): TimeFormat {
		if (this.settings.time) {
			// Фикс для совместимости со старыми параметрами, где наименование
			// UTC и LOC, новое Z и L
			if (this.settings.time === 'UTC') {
				return TimeFormat.Z;
			} else if (this.settings.time === 'LOC') {
				return TimeFormat.L;
			} else {
				return this.settings.time as unknown as TimeFormat;
			}
		} else {
			// Вернуть UTC если параметр не задан
			return TimeFormat.Z;
		}
	}

	setTime(code: string) {
		this.settings.time = code;
		this.time$.next(code as TimeFormat);
		this.updateSettingsStream();
	}

	getTimeZone() {
		return this.settings.timezone;
	}

	setTimeZone(name: string) {
		this.settings.timezone = name;
		this.timeZone$.next(name);
		this.updateSettingsStream();
	}

	getLoadableModule() {
		return this.settings.loadableModule;
	}

	setLoadableModule(id: number) {
		this.settings.loadableModule = id;
		this.updateSettingsStream();
	}

	private updateSettingsStream() {
		this.settings$.next({ ...this.settings$.getValue(), ...this.settings });
	}

	getLinearCenteringGraphicsType() {
		return this.settings.linearCenteringGraphicsType;
	}

	setLinearCenteringGraphicsType(type) {
		this.settings.linearCenteringGraphicsType = type;
		this.updateSettingsStream();
	}

	getDisplayedPeriod() {
		return this.settings.displayedPeriod;
	}

	setDisplayedPeriod(value) {
		this.settings.displayedPeriod = value;
		this.updateSettingsStream();
	}

	getShowFolderThree() {
		return this.settings.showFolderThree;
	}

	setShowFolderThree(value) {
		this.settings.showFolderThree = value;
		this.updateSettingsStream();
	}

	getCopyTextForReply() {
		return this.settings.copyTextForReply;
	}

	setCopyTextForReply(value) {
		this.settings.copyTextForReply = value;
		this.updateSettingsStream();
	}

	getFavoriteFolderId() {
		return this.settings.favoriteFolderId;
	}

	setFavoriteFolderId(id) {
		this.settings.favoriteFolderId = id;
		this.updateSettingsStream();
	}

	getNotificationSubscribe() {
		return this.settings.notificationsSubscribe;
	}

	setNotificationSubscribe(value) {
		this.settings.notificationsSubscribe = value;
		this.notificationsSubscribe$.next(value);
		this.updateSettingsStream();
	}

	setNotificationState(value) {
		this.settings.notificationsIsEnabled = value;
		this.notificationsIsEnabled$.next(value);
		this.updateSettingsStream();
	}

	getComManUserFilters() {
		return this.settings.comManUserFilters.sort((i1, i2) => i2.id - i1.id);
	}

	addComManUserFilter(name, folder, data) {
		this.settings.comManUserFilters = this.settings.comManUserFilters.sort((i1, i2) => i2.id - i1.id);
		const id = this.settings.comManUserFilters.length ? this.settings.comManUserFilters[0].id + 1 : 0;
		const filter = JSON.stringify(data);
		this.settings.comManUserFilters.push({ id, name, folderId: folder.id, folderName: folder.name, data: filter });
		this.updateSettingsStream();
		return id;
	}

	updateComManUserFilter(id, data) {
		const filter = JSON.stringify(data);
		this.settings.comManUserFilters = this.settings.comManUserFilters.map(o => {
			if (o.id === id) {
				const newObject = {
					id,
					name: o.name,
					folderId: o.folderId,
					folderName: o.folderName,
					data: filter,
				};
				return newObject;
			} else {
				return o;
			}
		});
		// this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
		// 													JSON.stringify(this._userConfig), this.httpOptions).toPromise();
		this.updateSettingsStream();
	}

	getMessagePerPage() {
		return this.settings.perPage;
	}

	setMessagePerPage(value) {
		this.settings.perPage = value;
		this.updateSettingsStream();
	}

	removeComManUserFilter(id: number) {
		this.settings.comManUserFilters = this.settings.comManUserFilters.filter(el => el.id !== id);
		this.updateSettingsStream();
		// this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
		// 													JSON.stringify(this._userConfig), this.httpOptions).toPromise();
	}

	/**
	 * Функция проверяет, имеет ли пользователь определенную роль для доступа к
	 * модулю.
	 * @param {Module} id - модуль, для которого производится проверка прав.
	 */
	isUserHasRoleForAccessToModule(id: Module): boolean {
		let index = -1;
		switch (id) {
			case Module.VisualInforming:
				index = this.roles$.getValue()?.indexOf('aac_informing');
				break;
			case Module.ComMan:
				index = this.roles$.getValue()?.indexOf('aac_commodule');
				break;
			case Module.Workflow:
				index = this.roles$.getValue()?.indexOf('aac_workflow');
				break;
			case Module.WeightBalance:
				index = this.roles$.getValue()?.indexOf('aac_balance');
				break;
			case Module.WeightBalanceMobile:
				index = this.roles$.getValue()?.indexOf('aac_balance-mobile');
				break;
			case Module.LostFound:
				index = this.roles$.getValue()?.indexOf('aac_lostfound');
				break;
			case Module.Schedule:
				index = this.roles$.getValue()?.indexOf('aac_schedule');
				break;
			case Module.Test:
				index = this.roles$.getValue()?.indexOf('aac_test');
				break;
			case Module.Administration:
				index = this.roles$.getValue()?.indexOf('aac_admin');
				break;
		}
		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	}
}
