import { createReducer, on } from '@ngrx/store';
import {
  ControlServiceComponentActions,
  ControlServiceDefaultActions,
  ControlServiceReceiversActions,
  ControlServiceSendersActions,
} from './control-service.actions';
import { ApiResponse, ReceiverChannel, SenderChannel, Component, Status } from '@shared/data-provider/app-supporter';

export const controlServiceFeatureKey = 'controlService';

export interface ComponentsState {
  items: Component[];
  currentItem: Component;
  status: Status;
  error: ApiResponse | null;
  channels: ReceiverChannel[] | SenderChannel[];
  errorCommandForChannel: ApiResponse | null;
  errorForEditAddresses: ApiResponse | null;
}

export const initialState: ComponentsState = {
  items: [],
  currentItem: null,
  status: null,
  error: null,
  channels: [],
  errorCommandForChannel: null,
  errorForEditAddresses: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ControlServiceComponentActions.readSuccess,
    (state, payload): ComponentsState => ({ ...state, currentItem: payload.component })
  ),
  on(
    ControlServiceComponentActions.readAllSuccess,
    (state, payload): ComponentsState => ({ ...state, items: payload.components })
  ),
  on(
    ControlServiceComponentActions.createSuccess,
    (state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
  ),
  on(
    ControlServiceComponentActions.updateSuccess,
    (state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
  ),
  on(
    ControlServiceDefaultActions.restartSuccess,
    (state, payload): ComponentsState => ({ ...state, error: payload.apiResponse })
  ),
  on(
    ControlServiceDefaultActions.statusSuccess,
    (state, payload): ComponentsState => ({ ...state, status: payload.status })
  ),
  on(
    ControlServiceComponentActions.newItemSuccess,
    (state): ComponentsState => ({
      ...state,
      currentItem: null,
    })
  ),
  on(
    ControlServiceComponentActions.clearCurrentItemAndStatus,
    (state): ComponentsState => ({
      ...state,
      currentItem: null,
      status: null,
    })
  ),
  on(
    ControlServiceReceiversActions.readChannelsSuccess,
    (state, payload): ComponentsState => ({ ...state, channels: payload.channels })
  ),
  on(
    ControlServiceReceiversActions.readChannelsFailure,
    (state, payload): ComponentsState => ({ ...state, error: payload.apiResponse, channels: [] })
  ),
  on(
    ControlServiceReceiversActions.sendCommandSuccess,
    (state, payload): ComponentsState => ({
      ...state,
      errorCommandForChannel: payload.apiResponse,
    })
  ),
  on(
    ControlServiceReceiversActions.sendCommandFailure,
    (state, payload): ComponentsState => ({
      ...state,
      errorCommandForChannel: payload.apiResponse,
    })
  ),
  on(
    ControlServiceSendersActions.readChannelsSuccess,
    (state, payload): ComponentsState => ({ ...state, channels: payload.channels })
  ),
  on(
    ControlServiceSendersActions.readChannelsFailure,
    (state, payload): ComponentsState => ({ ...state, error: payload.apiResponse, channels: [] })
  ),
  on(
    ControlServiceSendersActions.sendCommandSuccess,
    (state, payload): ComponentsState => ({
      ...state,
      errorCommandForChannel: payload.apiResponse,
    })
  ),
  on(
    ControlServiceSendersActions.sendCommandFailure,
    (state, payload): ComponentsState => ({
      ...state,
      errorCommandForChannel: payload.apiResponse,
    })
  ),
  on(
    ControlServiceSendersActions.editAddressesSuccess,
    (state, payload): ComponentsState => ({
      ...state,
      errorForEditAddresses: payload.apiResponse,
    })
  ),
  on(
    ControlServiceSendersActions.editAddressesFailure,
    (state, payload): ComponentsState => ({
      ...state,
      errorForEditAddresses: payload.apiResponse,
    })
  )
);
