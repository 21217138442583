import { Injectable } from '@angular/core';
import { ComManSetting, Folder } from './modules/com-man-setting';
import { KeycloakToken } from './keycloak-token';
import { WeightBalanceSettings, Workspace } from './modules/weight-balance-settings';
import { Settings } from './settings';
import { User, UserSettings } from './user';
import { Modules } from './modules';
import { Language } from './language';
import { TimeZone } from './time-zone';
import { Time } from './time';
import { v4 as uuidv4 } from 'uuid';
import { Language as LanguageList } from '@shared/models/language';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private comManSettings = new ComManSetting();
	private keycloakToken = new KeycloakToken();
	private weightBalanceSettings = new WeightBalanceSettings();
	private _language = LanguageList.en;
	languageList = new Language();
	module = new Modules();
	timeZone = new TimeZone();
	time = new Time();

	constructor(public user: User, public general: Settings) {
		// Подпись на получение настроек из профиля пользователя
		general.loadedUserSettings.subscribe(settings => {
			user.setUserSettings(settings);
		});

		// Подпись на получение токена пользователя
		user.token$.subscribe(token => {
			Object.assign(this.keycloakToken, token);
			this.comManSettings.setFolders(this.keycloakToken.folders);
			this.weightBalanceSettings.setWorkspaces(this.keycloakToken.workspaces);
		});

		// Подпись на получение логина при авторизации пользователя
		user.login$.subscribe(login => {
			if (login) {
				this.general.getUserSettings(login);
			}
		});

		user.roles$.subscribe(roles => {
			if (roles) {
				this.module.extractAvailableModulesForUserRoles(roles);
			}
		});

		// Обработка запроса на создание пользователя с настройками по умолчанию
		general.createUserFromDefaultSettings.subscribe(defaultSettings => {
			const userDefaultSettings = {
				...defaultSettings,
				...this.user.defaultSettings(),
				...this.comManSettings.defaultSettings(),
				...this.weightBalanceSettings.defaultSettings(),
			} as UserSettings;
			// ! должен ловить изменения из потока и раскидывать по всем конфиг модулям
			// user.setUserSettings(userDefaultSettings);
			this._language = defaultSettings.lang as LanguageList;
			general.createUserSettings(userDefaultSettings);
		});

		// Сейчас одинаковый конфиг для всех частей
		// далее надо модифицировать и сделать что бы в модули летело только
		// относящееся с ним. Но это переделывать файлы конфигурации, позже с
		// переводом на работу с БД
		general.applicationConfig$.subscribe(config => {
			// В каждом модуле надо собрать конфиги из загружаемых и дефолтных
			if (config) {
				user.setDefaultConfig(config);
				this.weightBalanceSettings.setDefaultConfig(config);
				this.comManSettings.setDefaultConfig(config);
			}
		});

		user.settings$.subscribe(settings => {
			if (settings) {
				general.setUserSettings(settings);
				this._language = settings.lang as LanguageList;
			}
		});
	}

	async initialize() {
		await this.general.initialize();
	}

	getComManFolders(): Folder[] {
		return this.comManSettings.getFolders();
	}

	getWeightBalanceWorkspaces(): Workspace[] {
		return this.weightBalanceSettings.getWorkspaces();
	}

	getRandomUuid(): string {
		return uuidv4();
	}

	getSitatexTypeRuAddress() {
		return this.comManSettings.getSitatexTypeRuAddress();
	}

	get language() {
		return this._language;
	}
}
