import { SettingsService } from "@core/services/settings/settings.service";
import { KeycloakService } from "keycloak-angular";
import { firstValueFrom, skip, switchMap } from "rxjs";

export function initializeKeycloak(keycloak: KeycloakService, settingsService: SettingsService) {
	return (): Promise<boolean> =>
		firstValueFrom(
			settingsService.general.keycloakConfig$.pipe(
				skip(1),
				switchMap(config => {
					return keycloak.init({
						config: {
							url: config.issuer,
							realm: config.realm,
							clientId: config.clientId,
						},
						loadUserProfileAtStartUp: false,
						initOptions: {
							onLoad: 'login-required',
							checkLoginIframe: false,
						},
						bearerExcludedUrls: ['/assets'],
					});
				})
			)
		);
}

export function initializeApplicationConfig(settingsService: SettingsService) {
  return () => settingsService.initialize();
}