import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';
import { Channel as ChannelItem } from '../../models/channel';
import { NgZorroAntdModule } from '../../../../ng-zorro-antd.module';
import { Store } from '@ngrx/store';
import { ControlServiceReceiversActions, ControlServiceSendersActions } from '../../store/control-service.actions';
import {
  selectEditAddressesForChannelResult,
  selectReceiverChannels,
  selectReceiverChannelsCommandResult,
} from '../../store/control-service.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component as ComponentItem } from '@shared/data-provider/app-supporter';
import { SendersEditModalComponent } from '../senders-edit-modal/senders-edit-modal.component';

@Component({
  selector: 'app-channel-list',
  standalone: true,
  imports: [NgZorroAntdModule, SendersEditModalComponent],
  templateUrl: './channel-list.component.html',
  styleUrl: './channel-list.component.less',
})
export class ChannelListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private message = inject(NzMessageService);
  globalI18n: GlobalI18n = inject(GlobalI18n);
  editedAddresses: string[] | null;
  editedChannel: string | null;
  showAddressEditModal = false;

  @Input()
  componentId: number | null = null;

  @Input()
  componentType: null | ComponentItem.ComponentTypeEnum = null;

  readonly components = this.store.selectSignal(selectReceiverChannels);
  readonly channelCommandResult$ = this.store.select(selectReceiverChannelsCommandResult);
  readonly channelEditAddressesResult$ = this.store.select(selectEditAddressesForChannelResult);

  listOfColumns = [
    /**
     * TODO некоторые поля отсутствуют в спецификации
     * удалить если не появятся позже
     */

    // {
    // 	name: this.globalI18n.getMessage(Module.ControlService, 'channelId'),
    // 	sortOrder: null,
    // 	sortDirections: ['ascend', 'descend', null],
    // 	compare: (a: ChannelItem, b: ChannelItem) => a.id - b.id,
    // 	priority: false,
    // },
    // {
    // 	name: this.globalI18n.getMessage(Module.ControlService, 'channelType'),
    // 	sortOrder: 'descend',
    // 	sortDirections: ['ascend', 'descend', null],
    // 	compare: (a: ChannelItem, b: ChannelItem) => a.type.localeCompare(b.type),
    // 	priority: 1,
    // },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'channelName'),
      sortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ChannelItem, b: ChannelItem) => a.name.localeCompare(b.name),
      priority: 1,
    },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'channelSenders'),
      sortOrder: 'null',
      priority: 0,
    },
    // {
    // 	name: this.globalI18n.getMessage(Module.ControlService, 'channelSenderAddress'),
    // 	sortOrder: null,
    // 	sortDirections: ['ascend', 'descend', null],
    // 	compare: (a: ChannelItem, b: ChannelItem) => a.senderAddress.localeCompare(b.senderAddress),
    // 	priority: 2,
    // },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'channelStatus'),
      sortOrder: null,
      sortDirections: ['descend', null],
      compare: (a: ChannelItem, b: ChannelItem) => a.enabled != b.enabled,
      priority: 2,
    },
    // {
    // 	name: this.globalI18n.getMessage(Module.ControlService, 'channelErrorOnQueue'),
    // 	sortOrder: null,
    // 	sortDirections: ['ascend', 'descend', null],
    // 	compare: (a: ChannelItem, b: ChannelItem) => a.error.localeCompare(b.error),
    // 	priority: 3,
    // },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'channelType'),
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ChannelItem, b: ChannelItem) => a.type.localeCompare(b.type),
      priority: 3,
    },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'channelControl'),
    },
  ];

  addressEditModalIsVisibleChange(visibility: boolean) {
    this.showAddressEditModal = visibility;
    this.store.dispatch(
      ControlServiceSendersActions.editAddresses({
        id: this.componentId,
        channel: this.editedChannel,
        addresses: this.editedAddresses,
      })
    );
  }

  ngOnInit(): void {
    this.reloadChannels();
    this.channelCommandResult$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => {
          if (response?.type === 'ok') {
            this.reloadChannels();
          } else if (response?.message) {
            this.message.create('error', response?.message, {
              nzDuration: 10000,
            });
          }
        })
      )
      .subscribe();

    this.channelEditAddressesResult$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => {
          if (response?.type === 'ok') {
            this.reloadChannels();
          } else if (response?.message) {
            this.message.create('error', response?.message, {
              nzDuration: 10000,
            });
          }
        })
      )
      .subscribe();
  }

  reloadChannels() {
    switch (this.componentType) {
      case ComponentItem.ComponentTypeEnum.Receiver:
        this.store.dispatch(ControlServiceReceiversActions.readChannels({ id: this.componentId }));
        break;
      case ComponentItem.ComponentTypeEnum.Sender:
        this.store.dispatch(ControlServiceSendersActions.readChannels({ id: this.componentId }));
        break;
      default:
        console.warn('Unsupported component type', this.componentType);
        break;
    }
  }

  sendCommandToChannel(channelName: string, operation: 'start' | 'stop') {
    this.store.dispatch(
      ControlServiceReceiversActions.sendCommand({ id: this.componentId, channel: channelName, operation: operation })
    );
  }

  editSenders(address: string[], channel: string) {
    this.editedAddresses = address;
    this.editedChannel = channel;
    this.showAddressEditModal = true;
  }
}
