import { Component, inject, input, model, OnChanges, output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';

@Component({
  selector: 'app-senders-edit-modal',
  standalone: true,
  imports: [NgZorroAntdModule, FormsModule],
  templateUrl: './senders-edit-modal.component.html',
  styleUrl: './senders-edit-modal.component.less',
})
export class SendersEditModalComponent implements OnChanges {
  sendersEditModalIsVisible = input<boolean>();
  sendersEditModalIsVisibleChange = output<boolean>();
  channelName = input<string>();
  senders = model<string[]>();
  editAddressIndex: number | null = null;
  sendersEdited: string[] | null = null;

  private readonly globalI18n = inject(GlobalI18n);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['senders']) {
      this.sendersEdited = changes['senders'].currentValue;
    }
  }

  handleEditAddressesOk(): void {
    this.senders.set(this.sendersEdited);
    this.sendersEditModalIsVisibleChange.emit(false);
  }

  handleEditAddressesCancel(): void {
    this.sendersEditModalIsVisibleChange.emit(false);
  }

  addAddress() {
    this.sendersEdited = [...this.sendersEdited, ''];
    this.editAddressIndex = this.sendersEdited.length - 1;
  }

  startEdit(index: number): void {
    this.editAddressIndex = index;
  }

  stopEdit(address: string, index: number): void {
    this.sendersEdited[index] = address;
    this.editAddressIndex = null;
  }

  deleteRow(address: string): void {
    this.sendersEdited = this.sendersEdited.filter(d => d !== address);
  }
}
