import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectComponentsItems } from '../../store/control-service.selectors';
import { Component as ComponentItem } from '@shared/data-provider/app-supporter';
import { ControlServiceComponentActions } from '../../store/control-service.actions';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';

@Component({
  selector: 'app-control',
  standalone: false,
  templateUrl: './control.component.html',
  styleUrl: './control.component.less',
})
export class ControlComponent implements OnInit {
  private readonly store = inject(Store);
  readonly components = this.store.selectSignal(selectComponentsItems);
  showComponentModal = false;
  private globalI18n = inject(GlobalI18n);

  listOfColumns = [
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'columnId'),
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ComponentItem, b: ComponentItem) => a.id - b.id,
      priority: false,
    },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'columnOrganization'),
      sortOrder: 'descend',
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ComponentItem, b: ComponentItem) => a.org.localeCompare(b.org),
      priority: 1,
    },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'columnProject'),
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ComponentItem, b: ComponentItem) => a.project.localeCompare(b.project),
      priority: 2,
    },
    {
      name: this.globalI18n.getMessage(Module.ControlService, 'columnComponent'),
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      compare: (a: ComponentItem, b: ComponentItem) => a.componentType.localeCompare(b.componentType),
      priority: 3,
    },
  ];

  ngOnInit() {
    this.store.dispatch(ControlServiceComponentActions.readAll());
  }

  showNewComponentModal(): void {
    this.showComponentModal = true;
  }

  componentNewModalIsVisibleChange(visibility: boolean) {
    this.showComponentModal = visibility;
  }
}
