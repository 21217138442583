<p
  class="m-2 text-blue font-weight-bold h5"
  i18n="@@control-service.component.channels.header">
  Channels
</p>
<nz-input-group
  nzAddOnBefore="Message without channel"
  class="w-25 m-2"
  i18n-nzAddOnBefore="@@control-service.component.channels.messageWithoutChannel">
  <!-- <input type="text" nz-input [(ngModel)]="inputValue" /> -->
  <input
    type="text"
    nz-input
    value="" />
</nz-input-group>
<nz-table
  #channelsTable
  nzTableLayout="fixed"
  [nzData]="components()"
  [nzShowPagination]="false">
  <thead>
    <tr>
      @for (column of listOfColumns; track column) {
      <th
        [nzSortFn]="column.compare"
        [nzSortPriority]="column.priority"
        [nzSortDirections]="column.sortDirections"
        [nzSortOrder]="column.sortOrder">
        {{ column.name }}
      </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (data of channelsTable.data; track data) {
    <tr>
      <td>{{ data.name }}</td>
      <td>
        <a
          (click)="editSenders(data.addresses, data.name)"
          class="text-light-blue"
          >{{ data.addresses }}</a
        >
      </td>
      <td>{{ data.enabled }}</td>
      <td>{{ data.type }}</td>
      <td>
        @if (!data.enabled) {
        <button
          nz-button
          nzType="primary"
          class="text-uppercase"
          i18n="@@control-service.component.channels.table.control.button.stop"
          (click)="sendCommandToChannel(data.name, 'start')">
          Start
        </button>

        } @else {
        <button
          nz-button
          nzDanger
          nzType="default"
          class="text-uppercase"
          i18n="@@control-service.component.channels.table.control.button.start"
          (click)="sendCommandToChannel(data.name, 'stop')">
          Stop
        </button>
        }
      </td>
    </tr>
    }
  </tbody>
</nz-table>

<!-- TODO для валидации адресов нужно передавать тип канала в channelName -->
<app-senders-edit-modal
  channelName="unknown"
  [(senders)]="editedAddresses"
  [sendersEditModalIsVisible]="showAddressEditModal"
  (sendersEditModalIsVisibleChange)="addressEditModalIsVisibleChange($event)"></app-senders-edit-modal>
