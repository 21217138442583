import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlServiceRoutingModule } from './control-service-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { ControlServiceEffects } from './store/control-service.effects';
import { AppSupporterApiModule } from '@shared/data-provider/app-supporter';
import { SettingsService } from '@core/services/settings/settings.service';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { StoreModule } from '@ngrx/store';
import * as fromControlService from './store/control-service.reducer';
import { ComponentModalComponent } from './components/component-modal/component-modal.component';
import { ChannelListComponent } from './components/channel-list/channel-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterModule } from '@angular/router';
import { ControlComponent } from './pages/control/control.component';

@NgModule({
	declarations: [ControlComponent],
	imports: [
		NgZorroAntdModule,
		FormsModule,
		ReactiveFormsModule,
		RouterLink,
		RouterModule,
		ComponentModalComponent,
		CommonModule,
		AppSupporterApiModule,
		NgZorroAntdModule,
		ControlServiceRoutingModule,
		ChannelListComponent,
		StoreModule.forFeature(fromControlService.controlServiceFeatureKey, fromControlService.reducer),
		EffectsModule.forFeature([ControlServiceEffects]),
	],
	providers: [SettingsService],
})
export class ControlServiceModule {}
