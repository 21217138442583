<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50">
				Log and reports
			</p>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white p-1">
		<div class="row bg-white h-100">
			<!-- Start tab reports options -->
			<ng-container *ngIf="showTab('menu-favorites') || showTab('menu-reports')">
				<div
					class="col-10 pt-3"
					*ngIf="showTab('menu-reports')">
					<div class="input-group">
						<div class="input-group-prepend">
							<span
								class="input-group-text"
								id="basic-addon1"
								i18n="rep|Reports search - find@@rep.reports.search.find"
								>Find</span
							>
						</div>
						<input
							type="text"
							class="form-control"
							placeholder="Enter report name"
							i18n-placeholder="rep|Reports search - enter report name@@rep.main.search.field.enterReportName"
							[(ngModel)]="searchReport" />
					</div>
					<hr />
					<ul class="nav nav-tabs mb-3">
						<ng-container *ngFor="let itemReportGroup of reportGroup">
							<li
								class="nav-item"
								*ngIf="checkUserRightForModule(itemReportGroup.rights)">
								<a
									class="nav-link"
									(click)="changeTabReport(itemReportGroup.id)"
									[ngClass]="{ active: showTabReport(itemReportGroup.id) }">
									{{ itemReportGroup.getTitle(language) }}
								</a>
							</li>
						</ng-container>
					</ul>
					<ng-container *ngFor="let itemReportGroup of reportGroup">
						<div *ngIf="reportActiveTab == itemReportGroup.id && checkUserRightForModule(itemReportGroup.rights)">
							<ul class="reportGroup">
								<li *ngFor="let itemReportSubGroup of itemReportGroup.reportSubGroup">
									<div class="pl-1">{{ itemReportSubGroup.getTitle(language) }}</div>
									<ul>
										<li
											*ngFor="let itemReportItem of itemReportSubGroup.reportItem"
											class="table-item-hover"
											[ngClass]="{
												favorite: itemReportItem.favorite == 1,
												'bg-gray': itemReportItem.id == reportActiveId
											}"
											(click)="changeReport(itemReportItem.id)">
											<i class="material-icons md-16 gold">grade</i> {{ itemReportItem.getTitle(language) }}
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</ng-container>
				</div>
				<div
					class="col-10 pt-3"
					*ngIf="showTab('menu-favorites')">
					<h1 i18n="rep|Reports title - favorites reports library@@rep.reports.title.favoritesReportsLibrary">
						Favorites reports library
					</h1>
					<div class="input-group">
						<div class="input-group-prepend">
							<span
								class="input-group-text"
								id="basic-addon1"
								i18n="rep|Reports search - find@@rep.reports.search.find"
								>Find</span
							>
						</div>
						<input
							type="text"
							class="form-control"
							placeholder="Enter report name"
							i18n-placeholder="rep|Reports search - enter report name@@rep.main.search.field.enterReportName"
							[(ngModel)]="searchReport" />
					</div>
					<hr />
					<ng-container *ngFor="let itemReportGroup of reportGroup">
						<ul class="favoriteReportGroup">
							<li *ngIf="issetFavoritesGroup(itemReportGroup.id)">
								<div class="p-3">{{ itemReportGroup.getTitle(language) }}</div>
								<ul>
									<ng-container *ngFor="let itemReportSubGroup of itemReportGroup.reportSubGroup">
										<li *ngIf="issetFavoritesSubGroup(itemReportGroup.id, itemReportSubGroup.id)">
											<div class="pl-3 bg-light-gray">
												<b>{{ itemReportSubGroup.getTitle(language) }}</b>
											</div>
											<ul>
												<ng-container *ngFor="let itemReportItem of itemReportSubGroup.reportItem">
													<li
														*ngIf="itemReportItem.favorite == 1"
														class="pl-3 table-item-hover"
														[ngClass]="{ 'bg-gray': itemReportItem.id == reportActiveId }"
														(click)="changeReport(itemReportItem.id)">
														<i
															class="material-icons md-16 gold pointer"
															ng-click="let thisShow = 0"
															>grade</i
														>
														{{ itemReportItem.getTitle(language) }}
													</li>
												</ng-container>
											</ul>
										</li>
									</ng-container>
								</ul>
							</li>
						</ul>
					</ng-container>
				</div>

				<div class="col-2 border-left pt-3 pb-5">
					<ng-container *ngIf="reportActive != null">
						<div
							class="rounded bg-light-gray px-2 py-1"
							[ngClass]="reportActive.getDescription(language) ? 'text-dark' : 'text-dark-light'">
							<b i18n="rep|Reports title - description@@rep.reports.title.description">Description</b>
						</div>
						<p class="text-dark-gray pl-2 pt-1">{{ reportActive.getDescription(language) }}</p>

						<div
							class="rounded bg-light-gray px-2 py-1"
							[ngClass]="reportActive.getFormula(language) ? 'text-dark' : 'text-dark-light'">
							<b i18n="rep|Reports title - calculation formula@@rep.reports.title.calculationFormula"
								>Calculation formula</b
							>
						</div>
						<p class="text-dark-gray pl-2 pt-1">{{ reportActive.getFormula(language) }}</p>

						<div
							class="rounded bg-light-gray px-2 py-1"
							[ngClass]="
								reportActive.visualizationOptions && reportActive.visualizationOptions.length > 0
									? 'text-dark'
									: 'text-dark-light'
							">
							<b i18n="rep|Reports title - possible display options@@rep.reports.title.possibleDisplayOptions"
								>Possible display options</b
							>
						</div>
						<ul class="text-dark-gray">
							<li *ngFor="let varVisual of reportActive.visualizationOptions">
								{{ reportVariantVisual[varVisual] }}
							</li>
						</ul>

						<div
							class="rounded bg-light-gray px-2 py-1"
							[ngClass]="reportActive.visual && reportActive.visual.length > 0 ? 'text-dark' : 'text-dark-light'">
							<b i18n="rep|Reports title - visualization method@@rep.reports.title.visualizationMethod"
								>Visualization method</b
							>
						</div>
						<ul class="text-dark-gray">
							<li *ngFor="let visual of reportActive.visual">
								{{ reportVisual[visual] }}
							</li>
						</ul>

						<button
							type="button"
							*ngIf="showTab('menu-reports')"
							id="report-{{ reportActiveId }}"
							(click)="my_loadReport(reportActiveId)"
							class="btn btn-sm btn-blue w-100"
							i18n="rep|Reports button - open report@@rep.reports.button.openReport">
							Open report
						</button>
						<div
							class="btn-group delete-favorite dropup w-100"
							*ngIf="showTab('menu-favorites')">
							<button
								class="btn btn-sm btn-blue w-100"
								type="button"
								id="report-{{ reportActiveId }}"
								(click)="my_loadReport(reportActiveId)"
								i18n="rep|Reports button - open report@@rep.reports.button.openReport">
								Open report
							</button>
							<button
								type="button"
								class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split"
								data-toggle="dropdown"></button>
							<div class="dropdown-menu dropdown-menu-right w-100 p-0">
								<button
									class="dropdown-item d-flex align-items-center"
									type="button">
									<i class="material-icons md-16">delete</i>
									<span i18n="rep|Reports button - delete from favorites@@rep.reports.button.deleteFromFavorites"
										>Delete from favorites</span
									>
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>

			<!--Start reports html new -->
			<ng-container *ngIf="activeTab === 'report'">
				<div class="col-10 pt-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb bg-white m-0">
							<li
								class="breadcrumb-item pointer"
								(click)="activeTab = 'menu-reports'">
								{{ arrTitleSearch(reportGroup, reportActiveTab) }}
							</li>
							<li class="breadcrumb-item active">{{ arrTitleSearch(reportItemAll, reportActiveId) }}</li>
						</ol>
					</nav>

					<div class="reportTopButtons mb-3 px-3">
						<button
							class="btn btn-light-gray btn-sm"
							(click)="modalEnterEmail = !modalEnterEmail"
							id="menu-compare"
							[disabled]="
								(reportActive.params.includes(14) && !reportParams.channel) ||
								(reportActive.params.includes(0) && !reportParams.id)
							">
							<i class="material-icons md-16">email</i>
							<span i18n="rep|Report button - share by mail@@rep.report.button.shareByMail">Share by mail</span>
						</button>

						<button
							class="btn btn-light-gray btn-sm"
							(click)="saveReport(reportActive.url, 'xlsx')"
							id="menu-compare"
							[disabled]="
								(reportActive.params.includes(14) && !reportParams.channel) ||
								(reportActive.params.includes(0) && !reportParams.id)
							">
							<i class="material-icons md-16">save_alt</i>
							<span i18n="rep|Report button - download report@@rep.report.button.downloadReport">Download report</span>
						</button>

						<button
							class="btn btn-light-gray btn-sm"
							(click)="print()"
							[disabled]="
								(reportActive.params.includes(14) && !reportParams.channel) ||
								(reportActive.params.includes(0) && !reportParams.id)
							">
							<i class="material-icons md-16">print</i>
							<span i18n="rep|Report button - print@@rep.report.button.print">Print</span>
						</button>
					</div>

					<div class="p-3 h-75">
						<div class="report h-100">
							<ul class="nav nav-tabs mb-3">
								<li class="nav-item">
									<a
										class="nav-link"
										*ngIf="
											reportView === 'chart' &&
											reportActiveData &&
											reportActiveData.chart &&
											reportActiveData.chart.length > 0
										"
										(click)="changeReportView('chart')"
										[ngClass]="{ active: reportView === 'chart' }"
										i18n="rep|Report tab - chart@@rep.report.tab.chart">
										Chart
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										(click)="changeReportView('table')"
										[ngClass]="{ active: reportView === 'table' }"
										i18n="rep|Report tab - data@@rep.report.tab.data">
										Data
									</a>
								</li>
							</ul>
							<ng-container *ngIf="reportActiveData && reportActiveData.data.length === 0">
								<div class="d-flex h-100 justify-content-center">
									<div class="my-auto">
										<p i18n="rep|Report@@rep.report.noData">NO DATA</p>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="reportView === 'chart' && reportActiveData && reportActiveData.chart.length > 0">
								<div class="">
									<p i18n="rep|Report title - data section@@rep.report.title.dataSection">Data section</p>
									<ng-container *ngFor="let item of reportsData[reportActiveId].chart">
										<span
											(click)="changeDiagramActive(item.id)"
											class="mr-1 pointer">
											<input
												[checked]="reportDiagramActive == item.id"
												type="radio"
												name="radioDiagramActive" />
											{{ item.title }}
										</span>
									</ng-container>
								</div>
								<div class="reporting-options">
									<div
										class="font-large mb-2"
										i18n="rep|Report title - reporting options@@rep.report.title.reportingOptions">
										Reporting options
									</div>
									<p class="m-0">
										<b i18n="rep|Report options - main@@rep.report.options.main">Main:</b>&nbsp;<span
											class="text-blue"
											i18n="rep|Report options - period@@rep.report.options.period"
											>Period:</span
										>&nbsp;01.01.2020 00:00 - 15.01.2020 00:00
									</p>
									<p class="m-0">
										<b i18n="rep|Report options - other@@rep.report.options.other">Other:</b>&nbsp;
										<span
											class="text-blue"
											i18n="rep|Report options - group by@@rep.report.options.groupBy"
											>Group by:</span
										>&nbsp;<span i18n="rep|Report options - group by@@rep.report.options.groupByPeople">People</span>
									</p>
									<p class="m-0">
										<b
											class="text-blue"
											i18n="rep|Report options - data sections@@rep.report.options.dataSections"
											>Data sections:</b
										>&nbsp;<span i18n="rep|Report options - data sections@@rep.report.options.dataSectionsPassengers"
											>Passengers</span
										>
									</p>
								</div>
							</ng-container>
							<!-- TODO вывод табличный данных -->
							<!-- Конструктор таблиц -->
							<ng-container *ngIf="showReportData && reportActiveData.data.length > 0">
								<div
									*ngIf="reportView === 'table'"
									class="row">
									<div class="col-12 pr-0 scroll-x">
										<ng-container *ngIf="reportActiveId === 42; else tableReportId40">
											<table class="table table-borderless table-hover">
												<thead>
													<tr class="text-muted detail-head table__mail-header--subhead">
														<ng-container *ngFor="let item of reportActiveData.titles">
															<th class="text-center">{{ item }}</th>
														</ng-container>
													</tr>
												</thead>
												<tbody class="font-small">
													<tr
														*ngFor="
															let row of reportActiveData.data[0].detailed
																| paginate
																	: {
																			itemsPerPage: itemsPerPage,
																			currentPage: currentPage,
																			totalItems: reportActiveData.data[0].detailed.length
																	  }
														">
														<ng-container *ngFor="let name of reportActiveData.dataNames">
															<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																<ng-container *ngFor="let item of row[name]">
																	<td class="text-center">{{ item }}</td>
																</ng-container>
															</ng-container>
															<ng-template #noObject>
																<td class="text-center">{{ row[name] }}</td>
															</ng-template>
														</ng-container>
													</tr>
												</tbody>
											</table>
											<br />
											<h5>TOTAL</h5>
											<table class="table table-borderless table-hover">
												<thead>
													<tr class="text-muted detail-head table__mail-header--subhead">
														<th class="text-center">Departure</th>
														<th class="text-center">Dispatcher</th>
														<th class="text-center">Number of flights</th>
													</tr>
												</thead>
												<tbody class="font-small">
													<tr *ngFor="let row of reportActiveData.data[0].total">
														<td class="text-center">{{ row['airport'] }}</td>
														<td class="text-center">{{ row['agent'] }}</td>
														<td class="text-center">{{ row['flights'] }}</td>
													</tr>
												</tbody>
											</table>
										</ng-container>
										<ng-template #tableReportId40>
											<ng-container *ngIf="reportActiveId === 40; else tableReportId39">
												<ng-container
													ng-container
													*ngIf="reportParams.reportType === 'totals'; else standardReportId40">
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.40Total.data.header.date">
																	Date
																</th>
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.40Total.data.header.numberPerDay">
																	Number per day
																</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="
																	let row of reportActiveData.data[0].report
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<td class="text-center">{{ row['dt'] }}</td>
																<td class="text-center">{{ row['amount'] }}</td>
															</tr>
														</tbody>
													</table>
												</ng-container>
												<ng-template #standardReportId40>
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<ng-container *ngFor="let item of reportActiveData.titles">
																	<th class="text-center">{{ item }}</th>
																</ng-container>
															</tr>
														</thead>
														<tbody class="font-small">
															<tr
																*ngFor="
																	let row of reportActiveData.data
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<ng-container *ngFor="let name of reportActiveData.dataNames">
																	<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																		<ng-container *ngFor="let item of row[name]">
																			<td class="text-center">{{ item }}</td>
																		</ng-container>
																	</ng-container>
																	<ng-template #noObject>
																		<td class="text-center">{{ row[name] }}</td>
																	</ng-template>
																</ng-container>
															</tr>
														</tbody>
													</table>
												</ng-template>
											</ng-container>
										</ng-template>
										<ng-template #tableReportId39>
											<ng-container *ngIf="reportActiveId === 39; else tableReportId37_38">
												<ng-container
													ng-container
													*ngIf="reportParams.reportType === 'totals'; else standardReportId39">
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<th
																	*ngIf="reportParams.reportGrouping === 'airport_airline'"
																	class="text-center"
																	i18n="rep|Report@@rep.report.39Total.data.header.airport">
																	Airport
																</th>
																<th
																	*ngIf="reportParams.reportGrouping !== 'airport_airline'"
																	class="text-center"
																	i18n="rep|Report@@rep.report.39Total.data.header.airline">
																	Airline
																</th>
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.39Total.data.header.pieces">
																	Pieces
																</th>
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.39Total.data.header.storageTime">
																	Storage time
																</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="
																	let row of reportActiveData.data
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<td class="text-center">{{ row['name'] }}</td>
																<td class="text-center">{{ row['pieces'] }}</td>
																<td class="text-center">{{ row['storageTime'] }}</td>
															</tr>
														</tbody>
													</table>
												</ng-container>
												<ng-template #standardReportId39>
													<table class="table table-borderless table-hover">
														<thead>
															<ng-container
																*ngIf="reportParams.reportGrouping === 'airport_airline'; else airlineAirport">
																<tr class="text-muted detail-head table__mail-header--subhead">
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.airport">
																		Airport
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.airline">
																		Airline
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.storage">
																		Storage
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.tag">
																		Tag
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.passenger">
																		Passenger
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.start">
																		Start
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.finish">
																		Finish
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.duration">
																		Duration
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.description">
																		Description
																	</th>
																</tr>
															</ng-container>
															<ng-template #airlineAirport>
																<tr class="text-muted detail-head table__mail-header--subhead">
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.airline">
																		Airline
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.airport">
																		Airport
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.storage">
																		Storage
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.tag">
																		Tag
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.passenger">
																		Passenger
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.start">
																		Start
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.finish">
																		Finish
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.duration">
																		Duration
																	</th>
																	<th
																		class="text-center"
																		i18n="rep|Report@@rep.report.39.data.header.description">
																		Description
																	</th>
																</tr>
															</ng-template>
														</thead>
														<tbody class="font-small">
															<tr
																*ngFor="
																	let row of reportActiveData.data
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<td class="text-center">
																	{{
																		reportParams.reportGrouping === 'airport_airline' ? row['airport'] : row['airline']
																	}}
																</td>
																<td class="text-center">
																	{{
																		reportParams.reportGrouping === 'airport_airline' ? row['airline'] : row['airport']
																	}}
																</td>
																<td class="text-center">{{ row['storage'] }}</td>
																<td class="text-center">{{ row['tag'] }}</td>
																<td class="text-center">{{ row['passenger'] }}</td>
																<td class="text-center">{{ row['dtStart'] }}</td>
																<td class="text-center">{{ row['dtFinish'] }}</td>
																<td class="text-center">{{ row['duration'] }}</td>
																<td class="text-center">{{ row['description'] }}</td>
															</tr>
														</tbody>
													</table>
												</ng-template>
											</ng-container>
										</ng-template>
										<ng-template #tableReportId37_38>
											<ng-container *ngIf="reportActiveId === 37 || reportActiveId === 38; else tableReportId36">
												<ng-container *ngIf="reportParams.reportType === 'totals'; else standardReportId37_38">
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<ng-container *ngFor="let item of reportActiveData.titles">
																	<th class="text-center">{{ item }}</th>
																</ng-container>
															</tr>
														</thead>
														<tbody class="font-small">
															<tr
																*ngFor="
																	let row of reportActiveData.data[0].report
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<ng-container *ngFor="let name of reportActiveData.dataNames">
																	<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																		<ng-container *ngFor="let item of row[name]">
																			<td class="text-center">{{ item }}</td>
																		</ng-container>
																	</ng-container>
																	<ng-template #noObject>
																		<td class="text-center">{{ row[name] }}</td>
																	</ng-template>
																</ng-container>
															</tr>
														</tbody>
													</table>
												</ng-container>
												<ng-template #standardReportId37_38>
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<ng-container *ngFor="let item of reportActiveData.titles">
																	<th class="text-center">{{ item }}</th>
																</ng-container>
															</tr>
														</thead>
														<tbody class="font-small">
															<tr
																*ngFor="
																	let row of reportActiveData.data[0].report
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data.length
																			  }
																">
																<ng-container *ngFor="let name of reportActiveData.dataNames">
																	<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																		<ng-container *ngFor="let item of row[name]">
																			<td class="text-center">{{ item }}</td>
																		</ng-container>
																	</ng-container>
																	<ng-template #noObject>
																		<td class="text-center">{{ row[name] }}</td>
																	</ng-template>
																</ng-container>
															</tr>
														</tbody>
													</table>
												</ng-template>
											</ng-container>
										</ng-template>
										<ng-template #tableReportId36>
											<ng-container *ngIf="reportActiveId === 36; else tableReportId35">
												<ng-container *ngIf="reportParams.reportType === 'totals'; else standardReportId36">
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.36.data.header.failStationDamage">
																	Fault Station Damage
																</th>
																<th class="text-center">01</th>
																<th class="text-center">02</th>
																<th class="text-center">03</th>
																<th class="text-center">04</th>
																<th class="text-center">05</th>
																<th class="text-center">06</th>
																<th class="text-center">07</th>
																<th class="text-center">08</th>
																<th class="text-center">09</th>
																<th class="text-center">10</th>
																<th class="text-center">11</th>
																<th class="text-center">12</th>
																<th class="text-center">13</th>
																<th class="text-center">14</th>
																<th class="text-center">15</th>
																<th class="text-center">16</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="
																	let row of reportActiveData.data[1]
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data[1].length
																			  }
																">
																<td class="text-center">{{ row['station'] }}</td>
																<td class="text-center">{{ row['01'] == 0 ? '' : row['01'] }}</td>
																<td class="text-center">{{ row['02'] == 0 ? '' : row['02'] }}</td>
																<td class="text-center">{{ row['03'] == 0 ? '' : row['03'] }}</td>
																<td class="text-center">{{ row['04'] == 0 ? '' : row['04'] }}</td>
																<td class="text-center">{{ row['05'] == 0 ? '' : row['05'] }}</td>
																<td class="text-center">{{ row['06'] == 0 ? '' : row['06'] }}</td>
																<td class="text-center">{{ row['07'] == 0 ? '' : row['07'] }}</td>
																<td class="text-center">{{ row['08'] == 0 ? '' : row['08'] }}</td>
																<td class="text-center">{{ row['09'] == 0 ? '' : row['09'] }}</td>
																<td class="text-center">{{ row['10'] == 0 ? '' : row['10'] }}</td>
																<td class="text-center">{{ row['11'] == 0 ? '' : row['11'] }}</td>
																<td class="text-center">{{ row['12'] == 0 ? '' : row['12'] }}</td>
																<td class="text-center">{{ row['13'] == 0 ? '' : row['13'] }}</td>
																<td class="text-center">{{ row['14'] == 0 ? '' : row['14'] }}</td>
																<td class="text-center">{{ row['15'] == 0 ? '' : row['15'] }}</td>
																<td class="text-center">{{ row['16'] == 0 ? '' : row['16'] }}</td>
															</tr>
														</tbody>
													</table>
													<br />
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<th
																	class="text-center"
																	i18n="rep|Report@@rep.report.36.data.header.failStationIrrCodes">
																	Fault Station IrrCodes
																</th>
																<th class="text-center">11</th>
																<th class="text-center">12</th>
																<th class="text-center">13</th>
																<th class="text-center">15</th>
																<th class="text-center">16</th>
																<th class="text-center">17</th>
																<th class="text-center">18</th>
																<th class="text-center">21</th>
																<th class="text-center">23</th>
																<th class="text-center">25</th>
																<th class="text-center">26</th>
																<th class="text-center">27</th>
																<th class="text-center">31</th>
																<th class="text-center">32</th>
																<th class="text-center">33</th>
																<th class="text-center">35</th>
																<th class="text-center">41</th>
																<th class="text-center">43</th>
																<th class="text-center">51</th>
																<th class="text-center">52</th>
																<th class="text-center">53</th>
																<th class="text-center">54</th>
																<th class="text-center">55</th>
																<th class="text-center">56</th>
																<th class="text-center">57</th>
																<th class="text-center">58</th>
																<th class="text-center">59</th>
																<th class="text-center">61</th>
																<th class="text-center">62</th>
																<th class="text-center">63</th>
																<th class="text-center">64</th>
																<th class="text-center">65</th>
																<th class="text-center">66</th>
																<th class="text-center">67</th>
																<th class="text-center">72</th>
																<th class="text-center">73</th>
																<th class="text-center">74</th>
																<th class="text-center">75</th>
																<th class="text-center">76</th>
																<th class="text-center">77</th>
																<th class="text-center">78</th>
																<th class="text-center">81</th>
																<th class="text-center">83</th>
																<th class="text-center">91</th>
																<th class="text-center">93</th>
																<th class="text-center">95</th>
																<th class="text-center">96</th>
																<th class="text-center">98</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="
																	let row of reportActiveData.data[2]
																		| paginate
																			: {
																					itemsPerPage: itemsPerPage,
																					currentPage: currentPage,
																					totalItems: reportActiveData.data[2].length
																			  }
																">
																<td class="text-center">{{ row['station'] }}</td>
																<td class="text-center">{{ row['11'] == 0 ? '' : row['11'] }}</td>
																<td class="text-center">{{ row['12'] == 0 ? '' : row['12'] }}</td>
																<td class="text-center">{{ row['13'] == 0 ? '' : row['13'] }}</td>
																<td class="text-center">{{ row['15'] == 0 ? '' : row['15'] }}</td>
																<td class="text-center">{{ row['16'] == 0 ? '' : row['16'] }}</td>
																<td class="text-center">{{ row['17'] == 0 ? '' : row['17'] }}</td>
																<td class="text-center">{{ row['18'] == 0 ? '' : row['18'] }}</td>
																<td class="text-center">{{ row['21'] == 0 ? '' : row['21'] }}</td>
																<td class="text-center">{{ row['23'] == 0 ? '' : row['23'] }}</td>
																<td class="text-center">{{ row['25'] == 0 ? '' : row['25'] }}</td>
																<td class="text-center">{{ row['26'] == 0 ? '' : row['26'] }}</td>
																<td class="text-center">{{ row['27'] == 0 ? '' : row['27'] }}</td>
																<td class="text-center">{{ row['31'] == 0 ? '' : row['31'] }}</td>
																<td class="text-center">{{ row['32'] == 0 ? '' : row['32'] }}</td>
																<td class="text-center">{{ row['33'] == 0 ? '' : row['33'] }}</td>
																<td class="text-center">{{ row['35'] == 0 ? '' : row['35'] }}</td>
																<td class="text-center">{{ row['41'] == 0 ? '' : row['41'] }}</td>
																<td class="text-center">{{ row['43'] == 0 ? '' : row['43'] }}</td>
																<td class="text-center">{{ row['51'] == 0 ? '' : row['51'] }}</td>
																<td class="text-center">{{ row['52'] == 0 ? '' : row['52'] }}</td>
																<td class="text-center">{{ row['53'] == 0 ? '' : row['53'] }}</td>
																<td class="text-center">{{ row['54'] == 0 ? '' : row['54'] }}</td>
																<td class="text-center">{{ row['55'] == 0 ? '' : row['55'] }}</td>
																<td class="text-center">{{ row['56'] == 0 ? '' : row['56'] }}</td>
																<td class="text-center">{{ row['57'] == 0 ? '' : row['57'] }}</td>
																<td class="text-center">{{ row['58'] == 0 ? '' : row['58'] }}</td>
																<td class="text-center">{{ row['59'] == 0 ? '' : row['59'] }}</td>
																<td class="text-center">{{ row['61'] == 0 ? '' : row['61'] }}</td>
																<td class="text-center">{{ row['62'] == 0 ? '' : row['62'] }}</td>
																<td class="text-center">{{ row['63'] == 0 ? '' : row['63'] }}</td>
																<td class="text-center">{{ row['64'] == 0 ? '' : row['64'] }}</td>
																<td class="text-center">{{ row['65'] == 0 ? '' : row['65'] }}</td>
																<td class="text-center">{{ row['66'] == 0 ? '' : row['66'] }}</td>
																<td class="text-center">{{ row['67'] == 0 ? '' : row['67'] }}</td>
																<td class="text-center">{{ row['72'] == 0 ? '' : row['72'] }}</td>
																<td class="text-center">{{ row['73'] == 0 ? '' : row['73'] }}</td>
																<td class="text-center">{{ row['74'] == 0 ? '' : row['74'] }}</td>
																<td class="text-center">{{ row['75'] == 0 ? '' : row['75'] }}</td>
																<td class="text-center">{{ row['76'] == 0 ? '' : row['76'] }}</td>
																<td class="text-center">{{ row['77'] == 0 ? '' : row['77'] }}</td>
																<td class="text-center">{{ row['78'] == 0 ? '' : row['78'] }}</td>
																<td class="text-center">{{ row['81'] == 0 ? '' : row['81'] }}</td>
																<td class="text-center">{{ row['83'] == 0 ? '' : row['83'] }}</td>
																<td class="text-center">{{ row['91'] == 0 ? '' : row['91'] }}</td>
																<td class="text-center">{{ row['93'] == 0 ? '' : row['93'] }}</td>
																<td class="text-center">{{ row['95'] == 0 ? '' : row['95'] }}</td>
																<td class="text-center">{{ row['96'] == 0 ? '' : row['96'] }}</td>
																<td class="text-center">{{ row['98'] == 0 ? '' : row['98'] }}</td>
															</tr>
														</tbody>
													</table>
												</ng-container>
												<ng-template #standardReportId36>
													<table class="table table-borderless table-hover">
														<thead>
															<tr class="text-muted detail-head table__mail-header--subhead">
																<ng-container *ngIf="reportParams.reportType === 'detailed'">
																	<ng-container *ngFor="let item of reportActiveData.titles">
																		<th class="text-center">{{ item }}</th>
																	</ng-container>
																</ng-container>
																<!-- <th class="text-center" *ngFor="let item of reportActiveData.titles">{{item}}</th> -->
															</tr>
														</thead>
														<tbody *ngIf="reportParams.reportType === 'totals'; else otherReport36">
															<ng-container *ngIf="reportParams.reportType === 'totals'">
																<tr
																	*ngFor="
																		let row of reportActiveData.data[0].report
																			| paginate
																				: {
																						itemsPerPage: itemsPerPage,
																						currentPage: currentPage,
																						totalItems: reportActiveData.data.length
																				  }
																	">
																	<td class="text-center">{{ row['statementType'] }}</td>
																	<td class="text-center">{{ row['statements'] }}</td>
																	<td class="text-center">{{ row['baggages'] }}</td>
																</tr>
															</ng-container>
														</tbody>
														<ng-template #otherReport36>
															<tbody class="font-small">
																<tr
																	*ngFor="
																		let row of reportActiveData.data[0].report
																			| paginate
																				: {
																						itemsPerPage: itemsPerPage,
																						currentPage: currentPage,
																						totalItems: reportActiveData.data.length
																				  }
																	">
																	<ng-container *ngFor="let name of reportActiveData.dataNames">
																		<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																			<ng-container *ngFor="let item of row[name]">
																				<td class="text-center">{{ item }}</td>
																			</ng-container>
																		</ng-container>
																		<ng-template #noObject>
																			<ng-container *ngIf="reportParams.reportType === 'detailed'">
																				<td class="text-center">{{ row[name] }}</td>
																			</ng-container>
																			<!-- <ng-container *ngIf="reportParams.reportType == 'totals'">
																				<td class="text-center">{{row[name]}}</td>
																			</ng-container> -->
																			<!-- <td class="text-center" *ngIf="reportParams.reportType == 'detailed' && name != 'cost' && name != 'pilferage' && name != 'commentaries'">{{row[name]}}</td> -->
																		</ng-template>
																	</ng-container>
																</tr>
																<tr>
																	<ng-container *ngFor="let name of reportActiveData.dataNames">
																		<!-- TODO убрано до опредления с форматом данных от бэка -->
																		<!-- <td class="text-center">{{reportActiveData.data.totals[name]}}</td> -->
																	</ng-container>
																</tr>
															</tbody>
														</ng-template>
													</table>
												</ng-template>
											</ng-container>
										</ng-template>
										<ng-template #tableReportId35>
											<table
												class="table table-borderless table-hover"
												*ngIf="reportActiveId === 35; else tableReportId34">
												<thead>
													<tr class="text-muted detail-head table__mail-header--subhead">
														<ng-container *ngIf="reportParams.reportType === 'detailed_long'">
															<ng-container *ngFor="let item of reportActiveData.titles">
																<th class="text-center">{{ item }}</th>
															</ng-container>
														</ng-container>
														<ng-container *ngIf="reportParams.reportType === 'detailed'">
															<ng-container *ngFor="let item of reportActiveData.titles">
																<th
																	class="text-center"
																	*ngIf="
																		item !== 'Cost' &&
																		item !== 'Pilferage Info' &&
																		item !== 'Comments' &&
																		item !== 'Стоимость' &&
																		item !== 'Кража' &&
																		item !== 'Примечание'
																	">
																	{{ item }}
																</th>
															</ng-container>
														</ng-container>
														<ng-container *ngIf="reportParams.reportType === 'totals'">
															<th
																class="text-center"
																i18n="rep|Report@@rep.report.35.data.header.typeofIrregularity">
																Type of irregularity
															</th>
															<th
																class="text-center"
																i18n="rep|Report@@rep.report.35.data.header.claim">
																Claim
															</th>
															<th
																class="text-center"
																i18n="rep|Report@@rep.report.35.data.header.bag">
																Bag
															</th>
														</ng-container>
														<!-- <th class="text-center" *ngFor="let item of reportActiveData.titles">{{item}}</th> -->
													</tr>
												</thead>
												<tbody *ngIf="reportParams.reportType === 'totals'; else otherReport35">
													<ng-container *ngIf="reportParams.reportType === 'totals'">
														<tr
															*ngFor="
																let row of reportActiveData.data[0].report
																	| paginate
																		: {
																				itemsPerPage: itemsPerPage,
																				currentPage: currentPage,
																				totalItems: reportActiveData.data.length
																		  }
															">
															<td class="text-center">{{ row['statementType'] }}</td>
															<td class="text-center">{{ row['statements'] }}</td>
															<td class="text-center">{{ row['baggages'] }}</td>
														</tr>
													</ng-container>
												</tbody>
												<ng-template #otherReport35>
													<tbody class="font-small">
														<tr
															*ngFor="
																let row of reportActiveData.data[0].report
																	| paginate
																		: {
																				itemsPerPage: itemsPerPage,
																				currentPage: currentPage,
																				totalItems: reportActiveData.data.length
																		  }
															">
															<ng-container *ngFor="let name of reportActiveData.dataNames">
																<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																	<ng-container *ngFor="let item of row[name]">
																		<td class="text-center">{{ item }}</td>
																	</ng-container>
																</ng-container>
																<ng-template #noObject>
																	<ng-container *ngIf="reportParams.reportType === 'detailed_long'">
																		<td class="text-center">{{ row[name] }}</td>
																	</ng-container>
																	<ng-container *ngIf="reportParams.reportType === 'detailed'">
																		<td
																			class="text-center"
																			*ngIf="name !== 'cost' && name !== 'pilferage' && name !== 'commentaries'">
																			{{ row[name] }}
																		</td>
																	</ng-container>
																</ng-template>
															</ng-container>
														</tr>
														<tr>
															<ng-container *ngFor="let name of reportActiveData.dataNames">
																<!-- TODO убрано до опредления с форматом данных от бэка -->
															</ng-container>
														</tr>
													</tbody>
												</ng-template>
											</table>
										</ng-template>
										<ng-template #tableReportId34>
											<table
												class="table table-borderless table-hover"
												*ngIf="reportActiveId === 34; else tableReportId31">
												<thead>
													<tr class="text-muted detail-head table__mail-header--subhead">
														<th
															class="text-center"
															*ngFor="let item of reportActiveData.titles">
															{{ item }}
														</th>
													</tr>
												</thead>
												<tbody class="font-small">
													<tr
														*ngFor="
															let row of reportActiveData.data[0].report
																| paginate
																	: {
																			itemsPerPage: itemsPerPage,
																			currentPage: currentPage,
																			totalItems: reportActiveData.data.length
																	  }
														">
														<ng-container *ngFor="let name of reportActiveData.dataNames">
															<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																<ng-container *ngFor="let item of row[name]">
																	<td
																		*ngIf="item !== ''"
																		class="text-center">
																		{{ item }}
																	</td>
																</ng-container>
															</ng-container>
															<ng-template #noObject>
																<td
																	*ngIf="row[name] !== undefined"
																	class="text-center">
																	{{ row[name] }}
																</td>
															</ng-template>
														</ng-container>
													</tr>
													<tr>
														<ng-container *ngFor="let name of reportActiveData.dataNames">
															<!-- TODO убрано до опредления с форматом данных от бэка -->
															<!-- <td class="text-center">{{reportActiveData.data.totals[name]}}</td> -->
														</ng-container>
													</tr>
												</tbody>
											</table>
										</ng-template>

										<ng-template #tableReportId31>
											<div
												class="d-flex h-100 justify-content-center"
												*ngIf="reportActiveId === 31; else tableReportDefault">
												<p
													class="my-auto"
													i18n="rep|Report@@rep.report.tab.data.reportIsBig">
													This report is too big, you can download it
												</p>
											</div>
										</ng-template>

										<ng-template #tableReportDefault>
											<table class="table table-borderless table-hover">
												<thead>
													<tr class="text-muted detail-head table__mail-header--subhead">
														<th
															class="text-center"
															*ngFor="let item of reportActiveData.titles">
															{{ item }}
														</th>
													</tr>
												</thead>
												<tbody class="font-small">
													<tr
														*ngFor="
															let row of reportActiveData.data
																| paginate
																	: {
																			itemsPerPage: itemsPerPage,
																			currentPage: currentPage,
																			totalItems: reportActiveData.data.length
																	  }
														">
														<ng-container *ngFor="let name of reportActiveData.dataNames">
															<ng-container *ngIf="checkType(row[name]) === 'object'; else noObject">
																<td
																	*ngFor="let item of row[name]"
																	class="text-center">
																	{{ item }}
																</td>
															</ng-container>
															<ng-template #noObject>
																<td class="text-center">{{ row[name] }}</td>
															</ng-template>
														</ng-container>
													</tr>
												</tbody>
											</table>
										</ng-template>
										<div
											class="loading"
											*ngIf="loading">
											<div class="cssload-spin-box"></div>
										</div>
									</div>
								</div>
							</ng-container>
							<!-- End of Конструктор таблиц -->
							<pagination-controls
								*ngIf="reportView === 'table' && reportActiveData?.data?.length > 0 && reportActiveId != 31"
								(pageChange)="currentPage = $event"
								maxSize="7"
								directionLinks="true"
								autoHide="true"
								previousLabel="Previous"
								nextLabel="Next"
								i18n-previousLabel="rep|Report - pagination previos@@rep.report.pagination.previousLabel"
								i18n-nextLabel="rep|Report - pagination next@@rep.report.pagination.nextLabel">
							</pagination-controls>
						</div>
					</div>
				</div>
				<div class="col-2 border-left pt-3 pb-5">
					<ng-container>
						<!-- ngIf="reportActiveId == 1 || reportActiveId == 2" -->
						<ul class="nav nav-tabs mb-3">
							<li class="nav-item">
								<a
									class="nav-link"
									(click)="changeReportRightTab('parameters')"
									[ngClass]="{ active: reportRightTab === 'parameters' }"
									i18n="rep|Report tab - parametrs@@rep.report.tab.parametrs">
									Parameters
								</a>
							</li>
						</ul>
						<form
							#reportForm="ngForm"
							class="form-report"
							*ngIf="reportRightTab === 'parameters'">
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(0)">
								<label
									for="report-id"
									class="mb-0"
									i18n="rep|Report parameters label - id@@rep.report.parameters.label.id">
									ID
								</label>
								<input
									type="text"
									id="report-id"
									class="form-control"
									[(ngModel)]="reportParams.id"
									name="reportId" />
							</div>
							<div
								class="row border-top border-bottom py-3 mb-3"
								*ngIf="like(reportActive.params, 1)">
								<div class="col-12">
									<div class="mb-3">
										<label
											for="period"
											class="mb-2"
											i18n="rep|Report parameters label - period@@rep.report.parameters.label.period"
											>Period</label
										>
										<div
											class="mb-2"
											*ngIf="reportParams.period !== 'manual-setting'">
											<div
												class="btn-group w-100"
												role="group"
												aria-label="">
												<button
													type="button"
													class="btn btn-sm btn-h-25 btn-light-gray"
													(click)="selectPeriod('current')"
													[ngClass]="{ 'btn-blue': selectedPeriod === 'current' }"
													i18n="rep|Report parameters button - current@@rep.report.parameters.button.current">
													Current
												</button>
												<button
													type="button"
													class="btn btn-sm btn-h-25 btn-light-gray"
													(click)="selectPeriod('previous')"
													[ngClass]="{ 'btn-blue': selectedPeriod === 'previous' }"
													i18n="rep|Report parameters button - previous@@rep.report.parameters.button.previous">
													Previous
												</button>
											</div>
										</div>
										<select
											class="form-control form-control-sm mb-1"
											[(ngModel)]="reportParams.period"
											name="reportParamsPeriod"
											id="reportParamsPeriod"
											(change)="changeParamsPeriod($event.target.value)">
											<option
												value="day"
												[selected]="reportParams.period === 'day'"
												i18n="rep|Report parameters period option - day@@rep.report.parameters.period.option.day">
												Day
											</option>
											<option
												value="week"
												[selected]="reportParams.period === 'week'"
												i18n="rep|Report parameters period option - week@@rep.report.parameters.period.option.week">
												Week
											</option>
											<option
												value="month"
												[selected]="reportParams.period === 'month'"
												i18n="rep|Report parameters period option - month@@rep.report.parameters.period.option.month">
												Month
											</option>
											<option
												value="qarter"
												[selected]="reportParams.period === 'qarter'"
												i18n="rep|Report parameters period option - qarter@@rep.report.parameters.period.option.qarter">
												Quarter
											</option>
											<option
												value="manual-setting"
												[selected]="reportParams.period === 'manual-setting'"
												i18n="
													rep|Report parameters period option - manual
													setting@@rep.report.parameters.period.option.manualSetting">
												Manual setting
											</option>
										</select>
										<div class="extra-small ta-right">
											<span i18n="rep|Report parameters period - range@@rep.report.parameters.period.range">Range:</span
											>&nbsp;{{ reportParams.start | date : 'dd-MM-yyyy HH:mm' : 'UTC+0' }}&nbsp;-&nbsp;{{
												reportParams.finish
													| date
														: 'dd-MM-yyyy
											HH:mm'
														: 'UTC+0'
											}}
										</div>
									</div>
									<ng-container *ngIf="reportParams.period === 'manual-setting'">
										<label
											for="reportParamsStartTime"
											class="mb-0">
											<span i18n="rep|Report parameters period - begin@@rep.report.parameters.period.begin">Begin</span>
											<span class="required-asterisk">*</span>
										</label>
										<div class="mb-2 row">
											<div class="col-5 pr-0">
												<input
													type="time"
													class="form-control"
													[ngModel]="reportParams.start | date : 'HH:mm' : 'UTC+0'"
													(input)="reportParams.start = parseUTCDate(start_date.value, $event.target.value)"
													name="reportParamsStartTime"
													#start_time="ngModel"
													[required]="reportParams.period === 'manual-setting'" />
												<div
													*ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="start_time.errors.required"
														i18n="
															rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
														Time is required
													</div>
												</div>
											</div>
											<div class="col-7 pl-1">
												<input
													type="date"
													class="form-control"
													[ngModel]="reportParams.start | date : 'yyyy-MM-dd' : 'UTC+0'"
													(input)="reportParams.start = parseUTCDate($event.target.value, start_time.value)"
													name="reportParamsStartDate"
													#start_date="ngModel"
													[required]="reportParams.period === 'manual-setting'" />
												<div
													*ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="start_date.errors.required"
														i18n="
															rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
														Date is required
													</div>
												</div>
											</div>
										</div>
										<label
											for="filter-date-from"
											class="mb-0">
											<span i18n="rep|Report parameters period - end@@rep.report.parameters.period.end">End</span>
											<span class="required-asterisk">*</span>
										</label>
										<div class="mb-2 row">
											<div class="col-5 pr-0">
												<input
													type="time"
													class="form-control"
													[ngModel]="reportParams.finish | date : 'HH:mm' : 'UTC+0'"
													(input)="reportParams.finish = parseUTCDate(finish_date.value, $event.target.value)"
													name="reportParamsFinishTime"
													#finish_time="ngModel"
													[required]="reportParams.period === 'manual-setting'" />
												<div
													*ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="finish_time.errors.required"
														i18n="
															rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
														Time is required
													</div>
												</div>
											</div>
											<div class="col-7 pl-1">
												<input
													type="date"
													class="form-control"
													[ngModel]="reportParams.finish | date : 'yyyy-MM-dd' : 'UTC+0'"
													(input)="reportParams.finish = parseUTCDate($event.target.value, finish_time.value)"
													name="reportParamsFinishDate"
													#finish_date="ngModel"
													[required]="reportParams.period === 'manual-setting'" />
												<div
													*ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)"
													class="invalid-feedback d-block">
													<div
														*ngIf="finish_date.errors.required"
														i18n="
															rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
														Date is required
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
							<div
								class="row"
								*ngIf="reportActive.params.includes(5)">
								<div class="col-12">
									<label
										for="reportParamsDate"
										class="mb-0">
										<span i18n="rep|Report parameters label - date@@rep.report.parameters.label.date">Date</span>
										<span class="required-asterisk">*</span>
									</label>
									<div class="mb-2 row">
										<div class="col-5 pr-0">
											<input
												type="time"
												class="form-control"
												[ngModel]="reportParams.date | date : 'HH:mm' : 'UTC+0'"
												(input)="reportParams.date = parseUTCDate(date_date.value, $event.target.value)"
												name="reportParamsDateTime"
												#date_time="ngModel" />
											<div
												*ngIf="!date_time?.valid && (date_time?.dirty || date_time?.touched)"
												class="invalid-feedback d-block">
												<div
													*ngIf="date_time.errors.required"
													i18n="rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
													Time is required
												</div>
											</div>
										</div>
										<div class="col-7 pl-1">
											<input
												type="date"
												class="form-control"
												[ngModel]="reportParams.date | date : 'yyyy-MM-dd' : 'UTC+0'"
												(input)="reportParams.date = parseUTCDate($event.target.value, date_time.value)"
												name="reportParamsDate"
												#date_date="ngModel" />
											<div
												*ngIf="!date_date?.valid && (date_date?.dirty || date_date?.touched)"
												class="invalid-feedback d-block">
												<div
													*ngIf="date_date.errors.required"
													i18n="rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
													Date is required
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(6)">
								<label
									for="report-airline"
									class="mb-0"
									i18n="rep|Report parameters label - airline@@rep.report.parameters.label.airline">
									Airline
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAirlines"
									bindLabel="iata"
									bindValue="id"
									[(ngModel)]="reportParams.airline"
									#reportAirline="ngModel"
									name="reportAirline"
									[virtualScroll]="true"
									[loading]="referanceAirlinesLoading"
									(scroll)="onScrollReferanceAirlines($event)"
									(scrollToEnd)="onScrollReferanceAirlinesToEnd()">
									<ng-template
										ng-option-tmp
										let-item="item">
										IATA: <span class="font-weight-bold">{{ item.iata }}</span
										>, ICAO: {{ item.icao }} <br />
										<small>{{ item.names }}</small>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(7)">
								<label
									for="report-airline"
									class="mb-0"
									i18n="rep|Report parameters label - movement@@rep.report.parameters.label.movement">
									Movement
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceMovements"
									bindLabel="name"
									bindValue="id"
									[(ngModel)]="reportParams.movement"
									name="reportMovement"
									#reportMovement="ngModel"
									[virtualScroll]="true"
									[loading]="referanceMovementsLoading"
									(scroll)="onScrollReferanceMovements($event)"
									(scrollToEnd)="onScrollReferanceMovementsToEnd()">
									<ng-template
										ng-option-tmp
										let-item="item">
										{{ item.code }} / {{ item.name }}
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(8)">
								<label
									for="report-geo"
									class="mb-0"
									i18n="rep|Report parameters label - geo@@rep.report.parameters.label.geo">
									Geo
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceGeoTypes"
									[bindLabel]="language === 'EN' ? 'nameEnglish' : 'nameLocal'"
									bindValue="id"
									name="reportGeo"
									id="reportGeo"
									[(ngModel)]="reportParams.geo">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span>{{ language === 'EN' ? item.nameEnglish : item.nameLocal }}</span>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(9)">
								<label
									for="report-aircraft"
									class="mb-0"
									i18n="rep|Report parameters label - aircraft@@rep.report.parameters.label.aircraft">
									Aircraft
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAircraftTypes"
									bindLabel="names"
									bindValue="id"
									name="reportAircraft"
									id="reportAircraft"
									[(ngModel)]="reportParams.aircraft"
									[virtualScroll]="true"
									[loading]="referanceAircraftTypesLoading"
									(scroll)="onScrollReferanceAircraftTypes($event)"
									(scrollToEnd)="onScrollReferanceAircraftTypesToEnd()">
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(10)">
								<label
									for="report-airport"
									class="mb-0"
									i18n="rep|Report parameters label - airport@@rep.report.parameters.label.airport">
									Airport
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAirports"
									bindLabel="iata"
									bindValue="id"
									[(ngModel)]="reportParams.airport"
									#reportAirport="ngModel"
									name="reportAirport"
									[virtualScroll]="true"
									[loading]="referanceAirportsLoading"
									(scroll)="onScrollReferanceAirports($event)"
									(scrollToEnd)="onScrollReferanceAirportsToEnd()">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span class="font-weight-bold">{{ item.iata }}</span
										>, {{ item.names }}
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(11)">
								<label
									for="report-season"
									class="mb-0"
									i18n="rep|Report parameters label - season@@rep.report.parameters.label.season">
									Season
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceSeasons"
									[bindLabel]="language === 'EN' ? 'nameEnglish' : 'nameLocal'"
									bindValue="id"
									name="reportSeason"
									id="reportSeason"
									[(ngModel)]="reportParams.season">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span>{{ language === 'EN' ? item.nameEnglish : item.nameLocal }}</span>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(12)">
								<label
									for="report-regularity"
									class="mb-0"
									i18n="rep|Report parameters label - regularity@@rep.report.parameters.label.regularity">
									Regularity
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceRegulatures"
									[bindLabel]="language === 'EN' ? 'nameEnglish' : 'nameLocal'"
									bindValue="id"
									name="reportRegularity"
									id="reportRegularity"
									[(ngModel)]="reportParams.regularity">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span>{{ language === 'EN' ? item.nameEnglish : item.nameLocal }}</span>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-3 mt-3"
								*ngIf="reportActive.params.includes(13)">
								<p class="mb-2">
									<b i18n="rep|Report parameters label - other@@rep.report.parameters.label.other">Other</b>
								</p>
								<label
									for="report-regularity"
									class="mb-0"
									i18n="rep|Report parameters label - groupBy@@rep.report.parameters.label.groupBy">
									Group by
								</label>
								<select
									class="form-control form-control-sm mb-1"
									[(ngModel)]="reportParams.groupBy"
									name="groupBy"
									id="groupBy">
									<option
										value="people"
										[selected]="reportParams.groupBy === 'people'"
										i18n="rep|Report parameters groupBy option - people@@rep.report.parameters.groupBy.option.people">
										People
									</option>
									<option
										value="day"
										[selected]="reportParams.groupBy === 'day'"
										i18n="rep|Report parameters groupBy option - day@@rep.report.parameters.groupBy.option.day">
										Day
									</option>
								</select>
							</div>
							<div
								class="mb-3 mt-3"
								*ngIf="reportActive.params.includes(14)">
								<p class="mb-2">
									<b i18n="rep|Report parameters label - other@@rep.report.parameters.label.other">Channel</b>
								</p>
								<label
									for="report-regularity"
									class="mb-0"
									i18n="rep|Report parameters label - channel@@rep.report.parameters.label.channel">
									Channel
								</label>
								<select
									class="form-control form-control-sm mb-1"
									[ngModel]="reportParams.channel"
									name="paramsChannel"
									id="paramsChannel"
									(change)="reportParams.channel = +$event.target.value"
									required>
									<option
										[selected]="!reportParams.channel"
										i18n="rep|Report parameters channel option - select@@rep.report.parameters.channel.option.select">
										Select...
									</option>
									<option
										*ngFor="let channel of referanceChannels"
										[value]="channel.id"
										[selected]="reportParams.channel == channel.id">
										{{ channel.name }}
									</option>
								</select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(25)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.grouping">
									Grouping
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportGroupItem1"
										id="reportGroupItem1"
										value="airport_airline"
										[(ngModel)]="reportParams.reportGrouping"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportGroupItem1"
										i18n="rep|Report parameters label@@rep.report.parameters.label.group.airportAirline">
										Airport, airline
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportGroupItem2"
										id="reportGroupItem2"
										value="airline_airport"
										[(ngModel)]="reportParams.reportGrouping"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportGroupItem2"
										i18n="rep|Report parameters label@@rep.report.parameters.label.group.airlineAirport">
										Airline, airport
									</label>
								</div>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(24)">
								<label
									for="report-airline"
									class="mb-0"
									i18n="rep|Report parameters label - airline@@rep.report.parameters.label.airline">
									Airline
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAirlines"
									bindLabel="iata"
									bindValue="id"
									[(ngModel)]="reportParams.airlines"
									#reportAirlines="ngModel"
									name="reportAirlines"
									[virtualScroll]="true"
									[multiple]="true"
									[loading]="referanceAirlinesLoading"
									(scroll)="onScrollReferanceAirlines($event)"
									(scrollToEnd)="onScrollReferanceAirlinesToEnd()">
									<ng-template
										ng-option-tmp
										let-item="item">
										IATA: <span class="font-weight-bold">{{ item.iata }}</span
										>, ICAO: {{ item.icao }} <br />
										<small>{{ item.names }}</small>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(15)">
								<label
									*ngIf="reportActiveId != 36; else faultStation"
									for="report-airports"
									class="mb-0"
									i18n="rep|Report parameters label - airport@@rep.report.parameters.label.airports">
									Airports
								</label>
								<ng-template #faultStation>
									<label
										for="report-airports"
										class="mb-0"
										i18n="rep|Report parameters label - airport@@rep.report.parameters.label.faultStation">
										Fault station
									</label>
								</ng-template>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAirports"
									bindLabel="iata"
									[multiple]="true"
									bindValue="id"
									[(ngModel)]="reportParams.airports"
									#reportAirports="ngModel"
									id="report-airports"
									name="reportAirports"
									[virtualScroll]="true"
									[loading]="referanceAirportsLoading"
									(scroll)="onScrollReferanceAirports($event)"
									(scrollToEnd)="onScrollReferanceAirportsToEnd()"
									[disabled]="routeNetworkOnly">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span class="font-weight-bold">{{ item.iata }}</span
										>, {{ item.names }}
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(16)">
								<label
									for="report-irregularityAirports"
									class="mb-0"
									i18n="
										rep|Report parameters label - irregularityAirports@@rep.report.parameters.label.irregularityAirports">
									Irregularity Airports
								</label>
								<ng-select
									class="form-control custom-sm border p-0"
									[items]="referanceAirports"
									bindLabel="iata"
									[multiple]="true"
									bindValue="id"
									[(ngModel)]="reportParams.irregularityAirports"
									#reportIrregularityAirports="ngModel"
									id="report-irregularityAirports"
									name="reportIrregularityAirports"
									[virtualScroll]="true"
									[loading]="referanceAirportsLoading"
									(scroll)="onScrollReferanceAirports($event)"
									(scrollToEnd)="onScrollReferanceAirportsToEnd()">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span class="font-weight-bold">{{ item.iata }}</span
										>, {{ item.names }}
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(26)">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value="false"
										id="routeNetworkCheckBox"
										[(ngModel)]="routeNetworkOnly"
										(change)="changeRouteNetwork()"
										name="reportTotals"
										#reportTotals="ngModel" />
									<label
										class="form-check-label"
										for="routeNetworkCheckBox"
										i18n="rep|Report parameters label - irregularityAirports@@rep.report.parameters.label.routeNetwork">
										Route network
									</label>
								</div>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(27)">
								<label
									for="report-username"
									class="mb-0"
									i18n="rep|Report parameters@@rep.report.parameters.label.agent">
									Agent
								</label>
								<ng-select
									class="form-control custom-sm border p-0 h-auto"
									[items]="referenceKeycloakUser"
									bindLabel="username"
									[multiple]="true"
									bindValue="username"
									[(ngModel)]="reportParams.usernames"
									#reportusername="ngModel"
									id="report-username"
									name="reportusername"
									[searchFn]="customSearchUser">
									<ng-template
										ng-option-tmp
										let-item="item">
										<span class="font-weight-bold">{{ item.username }}</span> {{ item.firstName }} {{ item.lastName }}
									</ng-template>
									<ng-template
										ng-label-tmp
										let-item="item"
										let-clear="clear">
										<span
											class="ng-value-icon left"
											(click)="clear(item)"
											>×</span
										>
										<span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
									</ng-template>
								</ng-select>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(28)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.rush">
									Rush
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportRushItem1"
										id="reportRushItem1"
										value="arrival"
										[(ngModel)]="reportParams.forwarding"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportRushItem1"
										i18n="rep|Report parameters label@@rep.report.parameters.label.forwarding.arrival">
										Arrival
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportRushItem2"
										id="reportRushItem2"
										value="departure"
										[(ngModel)]="reportParams.forwarding"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportRushItem2"
										i18n="rep|Report parameters label@@rep.report.parameters.label.forwarding.departure">
										Departure
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(17)">
								<!-- <label for="report-irregularityAirports" class="mb-0" i18n="rep|Report parameters label - irregularityAirports@@rep.report.parameters.label.totals">
									Totals
								</label>
								<ng-select class="form-control custom-sm border p-0"
													[items]="['true', 'false']"
													[(ngModel)]="reportParams.totals"
													#reportTotals="ngModel"
													id="reportTotals"
													name="reportTotals">
								</ng-select> -->

								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value="false"
										id="totalCheckBox"
										[(ngModel)]="reportParams.totals"
										name="reportTotals"
										#reportTotals="ngModel" />
									<label
										class="form-check-label"
										for="totalCheckBox"
										i18n="rep|Report parameters label - irregularityAirports@@rep.report.parameters.label.totals">
										Total
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(18)">
								<label
									for="report-irregularityAirports"
									class="mb-0"
									i18n="rep|Report parameters label@@rep.report.parameters.label.statementTypes">
									Type of irregularity
								</label>
								<ng-select
									class="select-long border p-0"
									[items]="references['statementTypes']"
									bindValue="nameEnglish"
									bindLabel="nameEnglish"
									groupBy="group"
									[multiple]="true"
									[selectableGroup]="true"
									[selectableGroupAsModel]="false"
									[closeOnSelect]="false"
									[(ngModel)]="reportParams.statementTypes"
									#reportStatementTypes="ngModel"
									id="reportStatementTypes"
									name="reportStatementTypes">
									<ng-template
										ng-optgroup-tmp
										let-item="item"
										let-item$="item$"
										let-index="index">
										<input
											id="item-{{ index }}"
											type="checkbox"
											[ngModel]="item$.selected"
											[ngModelOptions]="{ standalone: true }" />
										{{ item.group | uppercase }}
									</ng-template>
									<ng-template
										ng-option-tmp
										let-item="item"
										let-item$="item$"
										let-index="index">
										<input
											id="item-{{ index }}"
											type="checkbox"
											[ngModel]="item$.selected"
											[ngModelOptions]="{ standalone: true }" />
										{{ item.names }}
									</ng-template>
								</ng-select>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(19)">
								<p i18n="rep|Report parameters label@@rep.report.parameters.label.status">Status</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportStatus"
										id="reportStatuses1"
										value="all"
										[(ngModel)]="reportParams.statuses" />
									<label
										class="form-check-label"
										for="reportStatuses1"
										i18n="rep|Report@@rep.report.parameters.label.reportType.status.all">
										All
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportStatus"
										id="reportStatuses2"
										value="opened"
										[(ngModel)]="reportParams.statuses" />
									<label
										class="form-check-label"
										for="reportStatuses2"
										i18n="rep|Report@@rep.report.parameters.label.reportType.status.open">
										Open
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportStatus"
										id="reportStatuses3"
										value="closed"
										[(ngModel)]="reportParams.statuses" />
									<label
										class="form-check-label"
										for="reportStatuses3"
										i18n="rep|Report@@rep.report.parameters.label.reportType.status.close">
										Close
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(20)">
								<label
									for="report-irregularityAirports"
									class="mb-0"
									i18n="rep|Report parameters label@@rep.report.parameters.label.irregularityCode">
									Irregularity code
								</label>
								<ng-select
									class="select-long border p-0"
									[items]="references.irregularity_groups"
									bindValue="id"
									bindLabel="code"
									groupBy="group"
									[multiple]="true"
									[selectableGroup]="true"
									[selectableGroupAsModel]="false"
									[closeOnSelect]="false"
									[(ngModel)]="reportParams.irregularities"
									#reportIrregularities="ngModel"
									id="reportIrregularities"
									name="reportIrregularities"
									[dropdownPosition]="'left'">
									<ng-template
										ng-optgroup-tmp
										let-item="item"
										let-item$="item$"
										let-index="index">
										<input
											id="item-{{ index }}"
											type="checkbox"
											[ngModel]="item$.selected"
											[ngModelOptions]="{ standalone: true }" />
										{{ item.group | uppercase }}
									</ng-template>
									<ng-template
										ng-option-tmp
										let-item="item"
										let-item$="item$"
										let-index="index">
										<input
											id="item-{{ index }}"
											type="checkbox"
											[ngModel]="item$.selected"
											[ngModelOptions]="{ standalone: true }" />
										{{ item.code }} - {{ item.names }}
									</ng-template>
								</ng-select>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(21)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.reportType">
									Report type
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeLong"
										id="reportTypeLong1"
										value="detailed"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeLong1"
										i18n="rep|Report@@rep.report.parameters.label.reportType.detail">
										Detail
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeLong"
										id="reportTypeLong2"
										value="detailed_long"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeLong2"
										i18n="rep|Report@@rep.report.parameters.label.reportType.detailLong">
										Detail long
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeLong"
										id="reportTypeLong3"
										value="totals"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeLong3"
										i18n="rep|Report@@rep.report.parameters.label.reportType.total">
										Total
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(22)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.reportType">
									Report type
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeShort"
										id="reportTypeShort1"
										value="detailed"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeShort1"
										i18n="rep|Report@@rep.report.parameters.label.reportType.detailLong">
										Detail long
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeShort"
										id="reportTypeShort2"
										value="totals"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeShort2"
										i18n="rep|Report@@rep.report.parameters.label.reportType.total">
										Total
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(23)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.reportType">
									Report type
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeShortV2"
										id="reportTypeShortV21"
										value="detailed"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeShortV21"
										i18n="rep|Report@@rep.report.parameters.label.reportType.detail">
										Detail
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportTypeShortV2"
										id="reportTypeShortV22"
										value="totals"
										[(ngModel)]="reportParams.reportType"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportTypeShortV22"
										i18n="rep|Report@@rep.report.parameters.label.reportType.total">
										Total
									</label>
								</div>
							</div>

							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(29)">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value="false"
										id="foundOnBoard"
										[(ngModel)]="reportParams.foundOnBoard"
										name="reportFoundOnBoard"
										#reportFoundOnBoard="ngModel" />
									<label
										class="form-check-label"
										for="foundOnBoard"
										i18n="rep|Report parameters label - irregularityAirports@@rep.report.parameters.label.foundOnBoard">
										Found on board
									</label>
								</div>
							</div>
							<div
								class="mb-2"
								*ngIf="reportActive.params.includes(30)">
								<p
									class="mb-0 mt-3"
									i18n="rep|Report parameters label@@rep.report.parameters.label.Group">
									Group
								</p>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportCategory2"
										id="reportCategory2"
										value="airport"
										[(ngModel)]="reportParams.reportCategory"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportCategory2"
										i18n="rep|Report@@rep.report.parameters.label.reportCategory.airline">
										Airline
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="reportCategory1"
										id="reportCategory1"
										value="airline"
										[(ngModel)]="reportParams.reportCategory"
										(change)="clearReportData()" />
									<label
										class="form-check-label"
										for="reportCategory1"
										i18n="rep|Report@@rep.report.parameters.label.reportCategory.airport">
										Airport
									</label>
								</div>
							</div>
              <div
								class="mb-2"
								*ngIf="reportActive.params.includes(31)">
                <label
									for="report-irregularityAirports"
									class="mb-0"
									i18n="rep|Report parameters label@@rep.report.parameters.label.folders">
									Folders
								</label>
								<ng-select
									class="select-long border p-0"
									[items]="referanceFolders"
									bindValue="id"
									bindLabel="name"
                  groupBy="group"
									[multiple]="true"
									[selectableGroup]="true"
									[selectableGroupAsModel]="false"
									[closeOnSelect]="false"
									[(ngModel)]="reportParams.folders"
									#reportCommanFolders="ngModel"
									id="reportCommanFolders"
									name="reportCommanFolders"
									[dropdownPosition]="'left'">
                  <ng-template
										ng-optgroup-tmp
										let-item="item"
										let-item$="item$"
										let-index="index">
										<input
											id="item-{{ index }}"
											type="checkbox"
											[ngModel]="item$.selected"
											[ngModelOptions]="{ standalone: true }" />
										{{ item.group | uppercase }}
									</ng-template>
								</ng-select>
							</div>
							<button
								class="btn btn-blue btn-sm mt-2 w-100"
								(click)="my_loadReport()"
								i18n="rep|Report parameters button - create report@@rep.report.parameters.button.createReport">
								Create report
							</button>
						</form>
						<div
							class=""
							*ngIf="reportRightTab === 'favorites'">
							<div
								class="pb-1"
								i18n="rep|Report parameters label - parameters@@rep.report.parameters.label.parameters">
								Parameters
							</div>
							<div class="pb-3 border-bottom">
								<select
									class="form-control form-control-sm"
									name="select1">
									<option i18n="rep|Report parameters option - default@@rep.report.parameters.option.default">
										Default
									</option>
									<option i18n="rep|Report parameters option - not default@@rep.report.parameters.option.notDefault">
										Not default
									</option>
								</select>
							</div>
							<div class="pb-1 pt-3">
								<b><span i18n="rep|Report parameters label - email@@rep.report.parameters.label.email">Email</span></b>
							</div>
							<div
								class="pb-1"
								i18n="rep|Report parameters label - send to mail@@rep.report.parameters.label.sendToMail">
								Send to mail
							</div>
							<div class="pb-3">
								<select class="form-control form-control-sm">
									<option
										i18n="
											rep|Report parameters period send option -
											everyday@@rep.report.parameters.period.send.option.everyday">
										Everyday
									</option>
									<option
										i18n="
											rep|Report parameters period send option -
											yesterday@@rep.report.parameters.period.send.option.yesterday">
										Yesterday
									</option>
								</select>
							</div>
							<div
								class="pb-1"
								i18n="rep|Report parameters label - mail sending time@@rep.report.parameters.label.mailSendingTime">
								Mail sending time
							</div>
							<div class="pb-3">
								<input
									type="time"
									class="form-control form-control-sm"
									[ngModel]="reportParams.finish | date : 'HH:mm'"
									(input)="reportParams.finish = parseTime(finish_date, $event.target.value)"
									#finish_date="ngModel"
									required />
							</div>
							<div
								class="pb-1"
								i18n="rep|Report parameters label - adresses@@rep.report.parameters.label.adresses">
								Adresses
							</div>
							<div class="pb-3">
								<input
									class="form-control form-control-sm"
									value="plan@airport.com" />
							</div>
							<div class="btn-group delete-favorite dropup w-100">
								<button
									class="btn btn-sm btn-blue w-100"
									i18n="rep|Report parameters button - save favorites@@rep.report.parameters.button.saveFavorites">
									Save favorites
								</button>
								<button
									type="button"
									class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split"
									data-toggle="dropdown"></button>
								<div class="dropdown-menu dropdown-menu-right w-100">
									<button
										class="dropdown-item"
										type="button">
										<i class="material-icons md-16">delete</i>
										<span
											i18n="
												rep|Report parameters button - delete from
												favorites@@rep.report.parameters.button.deleteFromFavorites">
											Delete from favorites
										</span>
									</button>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<div
			*ngIf="messageWindow.show"
			class="ms-modal-message">
			<div class="modal-content w-50">
				<div
					class="modal-header"
					[ngClass]="{
						'bg-info': messageWindow.type === 'info' || messageWindow.type === 'confirm',
						'bg-warning': messageWindow.type === 'warning',
						'bg-danger': messageWindow.type === 'error'
					}">
					<h5
						*ngIf="messageWindow.type === 'info'"
						class="modal-title"
						i18n="rep|MessageWindow title - information@@rep.messageWindow.title.information">
						Information
					</h5>
					<h5
						*ngIf="messageWindow.type === 'confirm'"
						class="modal-title"
						i18n="rep|MessageWindow title - confirm action@@rep.messageWindow.title.confirmAction">
						Confirm action
					</h5>
					<h5
						*ngIf="messageWindow.type === 'warning'"
						class="modal-title"
						i18n="rep|MessageWindow title - warning@@rep.messageWindow.title.warning">
						Warning
					</h5>
					<h5
						*ngIf="messageWindow.type === 'error'"
						class="modal-title"
						i18n="rep|MessageWindow title - error@@rep.messageWindow.title.error">
						Error
					</h5>
					<button
						type="button"
						class="close"
						(click)="hideMessageWindow()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					{{ messageWindow.text }}
				</div>
				<div class="modal-footer bg-light-gray border-0">
					<button
						type="button"
						class="btn btn-sm btn-blue"
						(click)="messageWindow.func.call(this, messageWindow.id)"
						*ngIf="messageWindow.type === 'confirm'"
						i18n="rep|MessageWindow button - ok@@rep.messageWindow.button.ok">
						OK
					</button>
					<button
						type="button"
						class="btn btn-sm btn-secondary"
						(click)="hideMessageWindow()"
						i18n="rep|MessageWindow button - cancel@@rep.messageWindow.button.cancel">
						Cancel
					</button>
				</div>
			</div>
		</div>

		<div
			*ngIf="modalEnterEmail"
			class="ms-modal-message">
			<div
				class="loading"
				*ngIf="loading">
				<div class="cssload-spin-box"></div>
			</div>
			<div
				class="wrapper-answer wrapper-answer-check"
				*ngIf="messageSended">
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					stroke="green"
					stroke-width="3"
					stroke-dasharray="100">
					<path
						id="check"
						d="M 12,22 L 22,31 L 36,13"
						stroke-dashoffset="0">
						<animate
							attributeName="stroke-dashoffset"
							from="100"
							to="0"
							repeatCount="indefinite"
							dur="3s"></animate>
					</path>
				</svg>
				{{ systemMessagesTexts['messageSent'] }}
			</div>
			<div class="modal-content w-50">
				<div class="modal-header bg-info">
					<h5
						class="modal-title"
						i18n="rep|MessageWindow title - enter email@@rep.messageWindow.title.enterEmail">
						Enter emails
					</h5>
					<button
						type="button"
						class="close"
						(click)="modalEnterEmail = !modalEnterEmail">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<p i18n="rep|MessageWindow text - enter email@@rep.messageWindow.text.enterEmail">
						Enter email addresses separated by commas
					</p>
					<input
						type="text"
						name="searchReport"
						class="form-control"
						[(ngModel)]="reportParams.recipients" />
				</div>
				<div class="modal-footer bg-light-gray border-0">
					<button
						type="button"
						class="btn btn-sm btn-blue"
						(click)="sendByEmail()"
						i18n="rep|MessageWindow button - send@@rep.messageWindow.button.send">
						Send
					</button>
					<button
						type="button"
						class="btn btn-sm btn-secondary"
						(click)="modalEnterEmail = !modalEnterEmail"
						i18n="rep|MessageWindow button - cancel@@rep.messageWindow.button.cancel">
						Cancel
					</button>
				</div>
			</div>
		</div>
	</nz-content>
	<!-- <nz-footer></nz-footer> -->
</nz-layout>
