<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50"
				i18n="@@control-service.header.name">
				Control service
			</p>
			<button
				*nzSpaceItem
				nz-button
				nzType="default"
				(click)="back()">
				<span
					nz-icon
					nzType="left"
					class="inline-flex"></span>
				<span i18n="@@control-service.detail.button.back">Back</span>
			</button>
			<nz-input-group
				*nzSpaceItem
				nzAddOnBefore="Organization"
				class="mt-3"
				i18n-nzAddOnBefore="@@control-service.detail.info.organization">
				<input
					type="text"
					nz-input
					placeholder="{{ component()?.org }}"
					[disabled]="true" />
			</nz-input-group>
			<nz-input-group
				*nzSpaceItem
				nzAddOnBefore="Project"
				class="mt-3"
				i18n-nzAddOnBefore="@@control-service.detail.info.project">
				<input
					type="text"
					nz-input
					placeholder="{{ component()?.project }}"
					[disabled]="true" />
			</nz-input-group>
			<nz-input-group
				*nzSpaceItem
				nzAddOnBefore="Component"
				class="mt-3"
				i18n-nzAddOnBefore="@@control-service.detail.info.component">
				<input
					type="text"
					nz-input
					placeholder="{{ component()?.componentType }}"
					[disabled]="true" />
			</nz-input-group>
			<button
				*nzSpaceItem
				nz-button
				nzType="default"
				(click)="showEditComponentModal()"
				i18n="@@control-service.detail.button.editComponent">
				Edit component
			</button>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white">
		<div nz-row>
			<div
				nz-col
				nzSpan="24">
				<div
					nz-flex
					class="m-2"
					nzJustify="space-between">
					<div>
						<span
							nz-typography
							i18n="@@control-service.detail.info.status"
							>Status</span
						>
						<span
							nz-typography
							class="text-uppercase"
							><strong>{{ status()?.startedAt | date : 'dd.MM.yyyy HH:mm:ss' }}</strong></span
						>
						<button
							nz-button
							nzType="default"
							nzSize="default"
							(click)="refresh()"
							i18n="@@control-service.detail.button.refresh">
							Refresh
						</button>
					</div>
					<button
						nz-button
						nzType="default"
						(click)="restart()"
						i18n="@@control-service.detail.button.restart">
						Restart
					</button>
				</div>
				<nz-divider class="my-0"></nz-divider>
			</div>
		</div>
		<div nz-row>
			<app-channel-list
				[componentId]="componentId"
				[componentType]="componentType"></app-channel-list>
		</div>
		<app-component-modal
			[componentNewModalIsVisible]="showComponentModal"
			(componentNewModalIsVisibleChange)="componentNewModalIsVisibleChange($event)"></app-component-modal>
	</nz-content>
</nz-layout>
