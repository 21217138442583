import { createFeatureSelector, createSelector } from '@ngrx/store';
import { controlServiceFeatureKey, ComponentsState } from './control-service.reducer';

export const selectComponents = createFeatureSelector<ComponentsState>(controlServiceFeatureKey);

export const selectComponentsItems = createSelector(selectComponents, (state: ComponentsState) => state.items);

export const selectComponentsCurrentItem = createSelector(
  selectComponents,
  (state: ComponentsState) => state.currentItem
);

export const selectComponentsStatus = createSelector(selectComponents, (state: ComponentsState) => state.status);

export const selectReceiverChannels = createSelector(selectComponents, (state: ComponentsState) => state.channels);

export const selectReceiverChannelsCommandResult = createSelector(
  selectComponents,
  (state: ComponentsState) => state.errorCommandForChannel
);

export const selectEditAddressesForChannelResult = createSelector(
  selectComponents,
  (state: ComponentsState) => state.errorForEditAddresses
);
