<nz-modal
  nzTitle="Edit addresses"
  [nzVisible]="sendersEditModalIsVisible()"
  (nzOnCancel)="handleEditAddressesCancel()"
  (nzOnOk)="handleEditAddressesOk()"
  i18n-nzTitle="@@control-service.modal.senders.header.label">
  <ng-container *nzModalContent>
    <button
      nz-button
      (click)="addAddress()"
      nzType="primary"
      i18n="@@control-service.modal.senders.button.addAddress">
      Add address
    </button>
    <br />
    <br />
    <nz-table
      #editRowTable
      nzBordered
      [nzData]="sendersEdited"
      [nzFrontPagination]="false">
      <thead>
        <tr>
          <th i18n="@@control-service.modal.senders.table.header.address">Address</th>
          <th i18n="@@control-service.modal.senders.table.header.Action">Action</th>
        </tr>
      </thead>
      <tbody>
        @for (address of editRowTable.data; let index = $index; track index; ) {
        <tr class="editable-row">
          <td>
            <div
              class="editable-cell"
              [hidden]="editAddressIndex === index"
              (click)="startEdit(index)">
              {{ address }}
            </div>
            <input
              type="text"
              nz-input
              [hidden]="editAddressIndex !== index"
              [(ngModel)]="editRowTable.data[index]"
              (blur)="stopEdit($event.target.value, index)" />
          </td>
          <td>
            <a
              class="text-light-blue"
              nz-popconfirm
              nzPopconfirmTitle="Delete address?"
              (nzOnConfirm)="deleteRow(address)"
              i18n="@@control-service.modal.senders.table.button.delete"
              i18n-nzPopconfirmTitle="@@control-service.modal.senders.table.delete.confirmation"
              >Delete</a
            >
          </td>
        </tr>
        }
      </tbody>
    </nz-table>
  </ng-container>
</nz-modal>
