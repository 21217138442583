import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AppSupporterConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ComponentsService } from './api/components.service';
import { DefaultService } from './api/default.service';
import { ReceiversService } from './api/receivers.service';
import { SendersService } from './api/senders.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ComponentsService,
    DefaultService,
    ReceiversService,
    SendersService ]
})
export class AppSupporterApiModule {
    public static forRoot(configurationFactory: () => AppSupporterConfiguration): ModuleWithProviders<AppSupporterApiModule> {
        return {
            ngModule: AppSupporterApiModule,
            providers: [ { provide: AppSupporterConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AppSupporterApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AppSupporterApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
