import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { Output, EventEmitter } from '@angular/core';
import { TimeFormat } from '@shared/models/time-format';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { UserSettings } from './user';
import { Language } from './language';

interface KeycloakConfig {
	issuer: string;
	realm: string;
	clientId: string;
}

const EMPTY_KEYCLOAK_CONFIG = {
	issuer: null,
	realm: null,
	clientId: null,
};

export interface ApplicationConfig {
	// Язык системы по умолчанию
	lang: string;
	// Формат отображения времени в системе UTC или LOC для пользователя
	time: TimeFormat;
	// Часовой пояс по UTC
	timezone: string;
	// Идентификатор базового предприятия
	homeAirport: string;
	// Код аэропорта по умолчанию
	defaultAirportId: number;
	// Код авиакомпании по умолчанию
	defaultAirlineId: number;
	apiScheduleURL: string;
	apiCommunicationURL: string;
	apiReferanceURL: string;
	apiAdministrationURL: string;
	apiSlotCoordinationURL: string;
	apiWeightBalanceURL: string;
	apiLostFoundURL: string;
	apiWorkflowURL: string;
	apiTestURL: string;
	apiInformationalURL: string;
	apiTechProcessesURL: string;
	apiBoardingControlURL: string;
	apiReportURL: string;
	apiMaterialAccountingURL: string;
	// Адрес JSON сервера с настройками приложения
	apiJSONServer: string;
	// Адрес сервера логирования
	apiLogServer: string;
	// Тип предприятия: Аэропорт / Авиакомпания
	typeEnterprise: string;
	partnerLogo: string;
	aftnMaxMessageLength: number;
	sitatexTypeRuAddress: string;
	electronicDocumentFlow: boolean;
	apiWeightBalanceElectronicDocumentFlowUrl: string;
	apiNotificationURL: string;
	apiControlServiceURL: string;
}

const EMPTY_APP_CONFIG = {
	lang: null,
	time: TimeFormat.Z,
	timezone: null,
	homeAirport: null,
	defaultAirportId: null,
	defaultAirlineId: null,
	apiScheduleURL: null,
	apiCommunicationURL: null,
	apiReferanceURL: null,
	apiAdministrationURL: null,
	apiSlotCoordinationURL: null,
	apiWeightBalanceURL: null,
	apiLostFoundURL: null,
	apiWorkflowURL: null,
	apiTestURL: null,
	apiInformationalURL: null,
	apiTechProcessesURL: null,
	apiBoardingControlURL: null,
	apiReportURL: null,
	apiMaterialAccountingURL: null,
	apiJSONServer: null,
	apiLogServer: null,
	typeEnterprise: null,
	partnerLogo: null,
	aftnMaxMessageLength: 1500,
	sitatexTypeRuAddress: null,
	electronicDocumentFlow: true,
	apiWeightBalanceElectronicDocumentFlowUrl: null,
	apiNotificationURL: null,
	apiControlServiceURL: null,
};

@Injectable({
	providedIn: 'root',
})
export class Settings {
	@Output() changeDateFormatEvent = new EventEmitter<TimeFormat>();
	@Output() changeTimeZoneEvent = new EventEmitter<string>();
	@Output()
	createUserFromDefaultSettings = new EventEmitter<{
		lang: string;
		time: TimeFormat;
		timezone: string;
	}>();
	@Output() loadedUserSettings = new EventEmitter<UserSettings>();

	keycloakConfig$ = new BehaviorSubject<KeycloakConfig>(null);

	// private _defaultConfig = EMPTY_APP_CONFIG;
	applicationConfig$ = new BehaviorSubject<ApplicationConfig>(EMPTY_APP_CONFIG);

	// Текущее отображаемое время в системе UTC / LOC
	private _time: TimeFormat = TimeFormat.Z;
	// Часовой пояс из настроек пользователя
	private _timezone = '';
	// Смещение часового пояса относительно Зулу
	private _timezoneOffset = '';
	// Часовой пояс по умолчанию определенный из настроек пользователя
	private _timezoneDefault = '';
	private _perPage = null;
	private _displayedPeriod = null;
	private _partnerLogo = '';
	private _aftnMaxMessageLength = null;
	private _jsonServer = '';
	private _loadComplete = false;
	private _userSessionUUID = NIL_UUID;
	private _userId = '';

	userSettings$ = new BehaviorSubject<UserSettings | null>(null);
	private language: Language = new Language();

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(private httpClient: HttpClient) {
		// ! Разобраться для чего
		this._timezoneDefault = moment.tz.guess();
		// ! Разобраться для чего
		this._timezone = moment.tz.guess();
		// ! Разобраться для чего
		this._userSessionUUID = uuidv4();
	}

	async initialize() {
		// Считывание URL адреса из локального файла конфигурации приложения
		const config = await firstValueFrom(this.getSettingsServerUrl());
		this._jsonServer = config.apiJSONServer; //settingsArray.apiJSONServer;

		// Запрос конфигурации сервиса авторизации
		const keycloakConfig = await firstValueFrom(this.httpClient.get<KeycloakConfig>(this._jsonServer + '/keycloak'));
		if (keycloakConfig) {
			this.keycloakConfig$.next(keycloakConfig);
		}

		// Запрос базовой конфигурации приложения
		const applicationConfig = await firstValueFrom(
			this.httpClient.get<ApplicationConfig>(this._jsonServer + '/defaultConfig')
		);
		if (applicationConfig) {
			this.applicationConfig$.next(applicationConfig);
		}
	}

	get randomUuid(): string {
		return uuidv4();
	}

	get userSessionUuid(): string {
		return this._userSessionUUID;
	}

	private waitTimer(timer) {
		return new Promise<void>(resolve => {
			timer = timer || 100;
			setTimeout(() => {
				resolve();
			}, timer);
		});
	}

	async loadComplete() {
		while (!this._loadComplete) {
			await this.waitTimer(100);
		}
	}

	getSettingsServerUrl(): Observable<{ apiJSONServer: string }> {
		return this.httpClient.get<{ apiJSONServer: string }>('/assets/cfg/appConfig.json');
	}

	/**
	 * Функция возвращает значения определенного часового пояса в настройках
	 * учетной записи пользователя
	 */
	get timeZone(): string {
		return this._timezone;
	}

	/**
	 * Функция возвращает значение смещения часового пояса
	 */
	get timezoneOffset(): string {
		return this._timezoneOffset;
	}

	/**
	 * Функция устанавливает значение смещения часового пояса
	 */
	set timezoneOffset(offset) {
		this._timezoneOffset = offset;
	}

	/**
	 * Функция возвращает значения часового пояса из переменных окружения
	 * браузера пользователя
	 */
	get timezoneDefault(): string {
		return this._timezoneDefault;
	}

	/**
	 * Функция возвращает текущее формат отображения времени в системе
	 * UTC или LOC
	 */
	get time(): TimeFormat {
		return this._time;
	}

	get timeOffset(): string {
		if (this._time === TimeFormat.L) {
			return moment.tz(this._timezone).format('Z');
		} else {
			return moment.tz('Etc/UTC').format('Z');
		}
	}

	get perPage(): number {
		return this._perPage;
	}

	get displayedPeriod(): number {
		return this._displayedPeriod;
	}

	get partnerLogo(): string {
		return 'assets/img/partners/' + this._partnerLogo;
	}

	get aftnMaxMessageLength(): number {
		return this._aftnMaxMessageLength;
	}

	// TODO протестировать на пустой объект и на заполненный
	getUserSettings(user: string) {
		this.httpClient.get<UserSettings>(this._jsonServer + '/users/' + user).subscribe(
			userSettings => {
				// При нахождении пользователя загрузка всех его параметров в
				// класс пользовательского окружения
				if (Object.keys(userSettings).length !== 0) {
					// * засунуть объект в поток и в сервисе передать в Пользователя
					// Object.assign(this._userConfig, userSettings);
					// Закидываем настройки пользователя в поток
					this.userSettings$.next(userSettings);
					this.loadedUserSettings.emit(userSettings);

					// * Пользователь должен установить текущий язык системы
					// this._language = this._userConfig.lang || this._defaultConfig.lang;
					// * Пользователь должен установить текущую зону
					// this._timezone =
					// this._userConfig.timezone || this._defaultConfig.timezone;

					// * Пользователь должен установить текущее отображаемое время
					// if (data[userIndex].time.toUpperCase() === 'LOC') {
					//   this._time = TimeFormat.LOC;
					// } else if (data[userIndex].time.toUpperCase() === 'UTC') {
					//   this._time = TimeFormat.UTC;
					// } else {
					//   this._time = this._defaultConfig.time;
					// }
					// * Пользователь должен установить кол сообщений на странице
					// this._perPage = this._userConfig.perPage || 20;
					// * Пользователь должен установить период отображения рейсов (8,12,24 часа)
					// this._displayedPeriod = this._userConfig.displayedPeriod || 24;
				}

				// ! перенести установку параметров глобальных переменных в конструктор
				this._partnerLogo = this.applicationConfig$.getValue().partnerLogo;
				this._aftnMaxMessageLength = this.applicationConfig$.getValue().aftnMaxMessageLength;

				// Флаговать о завершении, может вывести какую то глобальную загрузку
				this._loadComplete = true;

				// * информация о смене формата времени или часовом поясе
				this.changeDateFormatEvent.emit(this._time);
				this.changeTimeZoneEvent.emit(this._timezone);
			},
			() => {
				this.createUserFromDefaultSettings.emit({
					lang: this.applicationConfig$.getValue().lang,
					time: this.applicationConfig$.getValue().time,
					timezone: this._timezoneDefault,
				});
				this._timezone = this.applicationConfig$.getValue().timezone;
				this._time = this.applicationConfig$.getValue().time;
				this._perPage = 20;
				this._displayedPeriod = 24;
			}
		);
	}

	setUserSettings(settings: UserSettings) {
		this.userSettings$.next(settings);
		this.updateUserSettings();
	}

	createUserSettings(settings: UserSettings) {
		this.httpClient.post(this._jsonServer + '/users', JSON.stringify(settings), this.httpOptions).subscribe(() => {
			this.getUserSettings(settings.id);
		});
	}

	updateUserSettings() {
		if (this.userSettings$.getValue().id) {
			this.httpClient
				.put(
					this._jsonServer + '/users/' + this.userSettings$.getValue().id,
					JSON.stringify(this.userSettings$.getValue()),
					this.httpOptions
				)
				.subscribe();
		}
	}

	getHomeAirport() {
		return +this.applicationConfig$.getValue().homeAirport;
	}

	getTypeEnterprise() {
		return +this.applicationConfig$.getValue().typeEnterprise;
	}

	getDefaultAirportId(): number {
		return +this.applicationConfig$.getValue().defaultAirportId;
	}

	getDefaultAirlineId(): number {
		return +this.applicationConfig$.getValue().defaultAirlineId;
	}

	getElectronicDocumentFlow(): boolean {
		return this.applicationConfig$.getValue().electronicDocumentFlow;
	}
}
